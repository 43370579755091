import React, { useState, useEffect, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';
import InstallBookSubTable from './InstallBookSubTable';

const InstallBook = forwardRef((props,ref) =>
{

//#region AddBudgetItemCode
    const [openInstallBook, setOpenInstallBook] = React.useState(false);
    const handleClickOpenInstallBook = () => 
    {
        setOpenInstallBook(true);
    };
    const handleCloseInstallBook = () => 
    {
        setOpenInstallBook(false);
    };
    ref.current = handleClickOpenInstallBook;
//#endregion

    useEffect(() => 
    {
        generateData(props.clientId );
    }, []);

    let USDollar = new Intl.NumberFormat('en-US', 
    {
        style: 'currency',
        currency: 'USD',
    });

    async function generateData( clientId )
    {
        let d = await Helpers.getSalesCodes( clientId );

        setTableData( d.salesCodeData );
    }

    const [tableData, setTableData] = useState([{Name:''}]);

    return(
<Dialog 
    open={openInstallBook}
    onClose={handleCloseInstallBook}

    PaperProps={{sx:{position:'fixed',width:'1200' }}}

    fullWidth
    maxWidth="lg"

    >
    <DialogTitle>

        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>Install Book</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseInstallBook}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent sx={{height:730, width:1200}}>
        <table border="1" style={{width: '100%'}}>
        <tbody>
          {
            tableData.map((obj) => {
              return (
                <tr>
                  <td>
                    <table><tr>
                        <td valign='top' style={{padding:'10px'}}>
                            <img src="Images/MPLogo.png" />
                        </td>
                        <td valign='top' style={{padding:'10px'}}>
                            Masterpiece Design Group<br />
                            6869 Stapoint Ct. Suite 104<br />
                            Winter Park, FL 32792
                        </td>
                        <td valign='top' style={{padding:'10px'}}>
                            Project: {props.Description} - {props.clientId}<br />
                            Designer
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <span style={{fontSize:30}}><b>{obj.Description}</b></span><br />                            
                        </td>
                    </tr>
                    </table>

                    <br />
                    <InstallBookSubTable clientId={props.clientId} salesCode={obj.SalesCode} />
                    <br />
                    {obj.Description}
                </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </DialogContent>
    <DialogActions>
        <Button autoFocus onClick={handleCloseInstallBook}>
            Thanks
        </Button>
    </DialogActions>
</Dialog>
    );

});

export default InstallBook;
