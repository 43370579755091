import React from 'react';

function CompanyBlock()
{

    return(
        <div>
            <table><tr>
            <td><img src="Images/MasterLogo2.png" alt="" /></td>
            <td>Masterpiece Interiors<br />6869 Stapoint Court, Suit 104<br />Winter Park, FL 32792<br />Phone: 407-740-5551 Fax: 407-679-5551</td>
            </tr></table>
        </div>
    );
}

export default CompanyBlock;
