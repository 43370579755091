import React from 'react';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

function Footer()
{

    return(
        <div>
                    <table style={{borderSpacing:"3px", borderCollapse:"separate"}}>
                        <tr>
                            <td style={{fontWeight:"bold", backgroundColor:"yellow"}}><div style={{width:110}}>New Order/Info Needed</div></td>
                            <td style={{fontWeight:"bold", backgroundColor:"lightgreen"}}><div style={{width:110}}>Processing/Paid</div></td>
                            <td style={{fontWeight:"bold", backgroundColor:"green"}}><div style={{width:110}}>Shipped</div></td>
                            <td style={{color:"white", fontWeight:"bold", backgroundColor:"purple"}}><div style={{width:110}}>Received</div></td>
                            <td style={{color:"white", fontWeight:"bold", backgroundColor:"red"}}><div style={{width:110}}>B/O</div></td>
                            <td style={{color:"red", fontWeight:"bold", backgroundColor:"black"}}><div style={{width:110}}>CNXL/DISCO PENDING</div></td>
                            <td style={{color:"white", fontWeight:"bold", backgroundColor:"black"}}><div style={{width:110}}>CNXL/DISCO</div></td>
                            <td style={{fontWeight:"bold", backgroundColor:"orange"}}><div style={{width:110}}>CLAIM</div></td>
                            <td style={{color:"white", fontWeight:"bold", backgroundColor:"gray"}}><div style={{width:110}}>RTS/TRSFR</div></td>
                            <td style={{color:"red", fontWeight:"bold", backgroundColor:"yellow"}}><div style={{width:110, border:"1px solid red"}}>ON HOLD/UNDER MIN</div></td>
                        </tr>
                    </table>
        </div>
        );
       
}

export default Footer;
