import React from 'react';
import Dropdown from 'react-dropdown';

import 'react-dropdown/style.css';
import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

function ProjectInfo(props)
{
    const options = 
    [
        'Room', 'Vendor'
    ];
    const defaultOption = options[0];
    let clientProject = props.clientProject;

    return(
        <div style={{height:40, padding:" 4px 2px 2px 2px"}}>
            <div style={{float:"left"}}>
                Client/Project: <input defaultValue={props.clientProject} ref={(c) => clientProject = c} type="text" id="clientProject" placeholder="Client/Project" /><button onClick={props.searchClick} style={{backgroundColor:"#0dcaf0"}} type="button">Search</button> | 
                 &nbsp;<label><input onChange={props.changeActiveOnly} defaultChecked={true} type="checkbox" style={{backgroundColor:"blue"}} />Active Only</label> | 
                &nbsp;<button onClick={props.createClick} style={{color:"white", backgroundColor:"#198754"}} type="button">Create New Project</button> | 
                Sort By: <select defaultValue={'Room'} onChange={props.changeSort} id="sortBy"><option value="Room">Room</option><option value="Vendor">Vendor</option></select>
            </div>
            <div style={{float:"left", position:"absolute", right:"0"}}>
                Designer: {props.designer} | Assistant: {props.assistant} | Install Date: {props.installDate}
            </div>
        </div>
        );
       
}

export default ProjectInfo;
