import React, { useEffect, useState, useRef } from 'react';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

function InfoButtons(props)
{

    localStorage.setItem('InstallBookType', "room");

    function changeInstallBook( event )
    {
        localStorage.setItem('InstallBookType', event.target.value);
        setTypeValue( event.target.value );
        props.installBook();
    }

    function clickInstallBook( event )
    {
        props.installBook();
    }

    const [_typeValue,setTypeValue] = useState('room');

    return(
        <div>
            <button onClick={props.addNewItem} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#198759',color:'white'}} type="button">Add New Item</button>
            <button onClick={props.addBudgetItem} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#ffc107',color:'black'}} type="button">Add Budget Item</button>
            <button onClick={props.createProposal} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#6c757d',color:'white'}} type="button">Create Proposal</button>
            <button onClick={props.createPO} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#0d6ef8',color:'white'}} type="button">Create PO</button>
            <button onClick={props.copy} style={{margin:'4px', borderColor:'#198759', border: "2px solid", backgroundColor:'white',color:'#198759'}} type="button">Copy</button>
            <button onClick={props.transfer} style={{margin:'4px', borderColor:'#E63545', border: "2px solid", backgroundColor:'white',color:'#E63545'}} type="button">Transfer</button>
            <button onClick={props.budgetSummary} style={{padding:'2px', border:'none', marginBottom:'4px', marginTop:'4px', marginLeft:'200px', marginRight:'4px', backgroundColor:'#0DCAF6',color:'black'}} type="button">Budget Summary</button>            
            <select onClick={clickInstallBook} value={_typeValue} onChange={changeInstallBook} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#0DCAF6',color:'black'}}><option value="room" selected>By Room</option><option value="cat" selected>By Category</option></select>
            <button onClick={props.changeOrders} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#0DCAF6',color:'black'}} type="button">Change Orders</button>
            <button onClick={props.clientApproval} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#0DCAF6',color:'black'}} type="button">Client Approval</button>
        </div>
        );
       
}

export default InfoButtons;
