import React, { useState, useEffect, forwardRef } from 'react';
import Helpers from '../pages/project2Helpers';

const InstallBookSubTable = forwardRef((props,ref) =>
{

    let iii;
    iii = 0;

    useEffect(() => 
    {
        let ibt = localStorage.getItem("intallBookType);")
        if( ibt == null )
        {
          ibt = "room";
        }
        generateData(props.clientId,props.salesCode,ibt);
    }, []);

    async function generateData( clientId, salesCode, ibt )
    {
        let d = await Helpers.getSalesCodeDetail( props.clientId, props.salesCode, ibt );
        d = d.salesCodeDetailData;

        let id =1 ;

        let jsonArray = [];
        for( let i=0; i<d.length; i++ )
        {
            let child = d[i];
            let row = {id:id,Room:child.Room,Qty:1,Description:child["Item Description"],Vendor:child.Vendor,Notes:''}
            jsonArray.push( row );
        }
        setTableData( jsonArray );
    }

    const [tableData, setTableData] = useState([]);

    return(
        <table border="1" style={{width: '100%'}}>
            
        <thead>
          <tr>
            <th align='center'>ID</th>
            <th align='center'>Room</th>
            <th align='center'>Qty</th>
            <th align='center'>Item Description</th>
            <th align='center'>Vendor</th>
            <th align='center'>Notes</th>
          </tr>
        </thead>

        <tbody>
          {
            tableData.map((obj) => {
              return (
                <tr>
                  <td style={{border:'1px solid black'}}>
                    {obj.id}
                  </td>
                  <td style={{border:'1px solid black'}}>
                    {obj.Room}
                  </td>
                  <td style={{border:'1px solid black'}}>
                    {obj.Qty}
                  </td>
                  <td style={{border:'1px solid black'}}>
                    {obj.Description}
                  </td>
                  <td style={{border:'1px solid black'}}>
                    {obj.Vendor}
                  </td>
                  <td style={{border:'1px solid black'}}>
                    {obj.Notes}
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );

});

export default InstallBookSubTable;
