import React, { useState, forwardRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';

const AddressDetails = forwardRef((props,ref) =>
{

	useEffect(() => 
	{
        processDropDowns();
	}, []);

    async function getAddressDataForID(id)
    {

        let addressData = await Helpers.getAddressData(id);
        addressData = addressData.retData[0];

        for( let i=0; i<setters.length; i+=3 )
        {
            if( setters[i+2].length == 0 )
            {
                Helpers.setTextField( setters[i], setters[i+1], addressData );
            }
            else
            {
                Helpers.setTextFieldOrZero( setters[i], setters[i+1], addressData );
            }
        }

        // Helpers.setTextField( setAddrId, "Address ID", addressData );
        // Helpers.setTextField( setCountry, "Country", addressData );
        // Helpers.setTextField( setDefaultType, "Type", addressData );
        // Helpers.setTextField( setType, "Type", addressData );
        // Helpers.setTextField( setDefaultActive, "Active", addressData );
        // Helpers.setTextField( setActive, "Active", addressData );
        // Helpers.setTextField( setDefaultInActive, "InActive Reason", addressData );
        // Helpers.setTextField( setInActive, "InActive Reason", addressData );
        // Helpers.setTextField( setProjectName, "Name", addressData );
        // Helpers.setTextField( setAddressL1, "Address", addressData );
        // Helpers.setTextField( setAddressL2, "Address 2", addressData );
        // Helpers.setTextField( setCity1, "City", addressData );
        // Helpers.setTextField( setDefaultState1, "State", addressData );
        // Helpers.setTextField( setDefaultState2, "State", addressData );
        // Helpers.setTextField( setZip1, "Zip", addressData );
        // Helpers.setTextField( setBillingName, "Mailing Name", addressData );
        // Helpers.setTextField( setAddressR1, "Mailing Address", addressData );
        // Helpers.setTextField( setAddressR2, "Mailing Address 2", addressData );
        // Helpers.setTextField( setCity2, "Mailing City", addressData );
        // Helpers.setTextField( setZip2, "Mailing Zip", addressData );
        // Helpers.setTextField( setPhone, "Phone", addressData );
        // Helpers.setTextField( setFax, "Fax", addressData );
        // Helpers.setTextField( setEmail, "Email", addressData );
        // Helpers.setTextField( setWebsite, "Web Site", addressData );
        // Helpers.setTextField( setLoginId, "Web Site ID", addressData );
        // Helpers.setTextField( setPassword, "Password", addressData );
        // Helpers.setTextField( setVendorAccount, "Vendor Account", addressData );
        // Helpers.setTextField( setVendorPolicy, "Vendor Policy", addressData );
        // Helpers.setTextField( setLeadTime, "Lead Time", addressData );
        // Helpers.setTextField( setMinimum, "Minimum", addressData );
        // Helpers.setTextField( setNotes, "Notes", addressData );
        // Helpers.setTextField( setSidemark, "Client Sidemark", addressData );
        // Helpers.setTextField( setAlert, "Alert", addressData );
        // Helpers.setTextField( setFOB, "FOB", addressData );
        // Helpers.setTextField( setAttention, "Attention", addressData );
        // // Code 1 dropdown
        // // Code 2 dropdown
        // // Code 3 dropdown
        // // Code 4 dropdown
        // // Code 5 dropdown
        // // Code 6 dropdown
        // // Code 7 dropdown
        // // Code 8 dropdown
        // // Code 9 dropdown
        // // Code 10 dropdown
        // Helpers.setTextField( setAccountNum, "Account #", addressData );
        // Helpers.setTextField( setFederalID, "Federal ID", addressData );
        // Helpers.setTextField( setSocialSec, "Social Security #", addressData );
        // Helpers.setTextField( setTaxLocation, "Tax Location", addressData );
        // Helpers.setTextField( setSalesTax1, "Tax Percent", addressData );
        // Helpers.setTextField( setSalesTax2, "Tax Percent 2", addressData );
        // Helpers.setTextFieldOrZero( set12, "Mark Up Selling", addressData );
        // Helpers.setTextFieldOrZero( set22, "Mark Up Freight", addressData );
        // Helpers.setTextFieldOrZero( set13, "Mark Up Crating", addressData );
        // Helpers.setTextFieldOrZero( set14, "Mark Up Installation", addressData );
        // Helpers.setTextFieldOrZero( set15, "Mark Up Other", addressData );
        // Helpers.setTextFieldOrZero( set21, "Proposal Selling", addressData );
        // Helpers.setTextFieldOrZero( set22, "Proposal Mark Up", addressData );
        // Helpers.setTextFieldOrZero( set23, "Proposal Freight", addressData );
        // Helpers.setTextFieldOrZero( set24, "Proposal Crating", addressData );
        // Helpers.setTextFieldOrZero( set25, "Proposal Installation", addressData );
        // Helpers.setTextFieldOrZero( set26, "Proposal Other", addressData );
        // Helpers.setTextFieldOrZero( set27, "Proposal Sales Tax", addressData );
        // Helpers.setTextField( setInstallDate, "Install Date", addressData );
    }

    async function processDropDowns()
    {
        let dropDownData = await Helpers.callGetDropDownsAPI();
        processDropDownJSONArray( setDesignerDropDown, dropDownData.designerData, "Value", "Text" );
        processDropDownJSONArray( setAssistantDropDown, dropDownData.assistantData, "Value", "Text" );
        processDropDownJSONArray( setTermsDropDown, dropDownData.termData, "Value", "Text" );
        processDropDownJSONArray( setFreightTermsDropDown, dropDownData.termData, "Value", "Text" );
        processDropDownJSONArray( setShipViaDropDown, dropDownData.viaData, "Value", "Text" );
        processDropDownJSONArray( setCashDataDropDown, dropDownData.cashData, "Account #", "Account Description" );
    }

    function processDropDownJSONArray( setFunction, array, valueKey, textKey )
    {
        let d = JSON.parse( array );
        let jsonArray = [];

        jsonArray.push( { value:'', label:''} );

        for( let i=0; i<d.length; i++ )
        {
            let value = d[i][valueKey].trim();
            let label = d[i][textKey];
            let jsonObject = { value:value, label:label};
            if( value.length > 0 )
            {
                jsonArray.push( jsonObject );
            }
        }

        setFunction( jsonArray );

        return jsonArray;
    }

    const [openSetAddressDetails, setOpenSetAddressDetails] = React.useState(false);
    const [_mode,setMode] = useState('new');
    const [_id,setID] = useState('');
    const [_title,setTitle] = useState('Create New Address Details');

    const [_showSecondColumn, setShowSecondColumn] = useState('visible');
    const [_showBlankFirstColumn, setShowBlankFirstColumn] = useState('none');
    const [_showFirstColumnInputs, setShowFirstColumnInputs] = useState('text');
    const [_field1, setField1] = useState('Selling %');
    const [_field2, setField2] = useState('Freight %');
    const [_column1, setColumn1] = useState('Client Mark Up %');

    const handleClickOpenAddressDetails = (mode,id) => 
    {
        setMode(mode);
        setID(id);
        if( mode=='edit' )
        {
            setTitle("Edit Address Details");
            getAddressDataForID(id);

            setShowSecondColumn('none');
            setShowBlankFirstColumn('block');
            setShowFirstColumnInputs('hidden');
            setColumn1('Vendor %');
            setField1('Vendor Disc %');
            setField2('Client Disc %');

            setProjInstallDateVisible( 'none');
            setProjInstallDateType('hidden');
            setEditVisible('block');
            setEditVisibleType('text');
        }
        else
        {
            setTitle("Create Address Details");

            for( let i=0; i<setters.length; i+=3 )
            {
                setters[i]( setters[i+2] );
            }

            setShowSecondColumn('block');
            setShowBlankFirstColumn('none');
            setShowFirstColumnInputs('text');
            setColumn1('Client Mark Up %');
            setField1('Selling %');
            setField2('Freight %');

            let userData = localStorage.getItem('user_data');
            let ud = JSON.parse(userData);
            setDesignerDropDownDefault( ud.employeeName );
            setDesignerValue( ud.employeeName );

            setSidemark('');

            setProjInstallDateVisible( 'block');
            setProjInstallDateType('text');
            setEditVisible('none');
            setEditVisibleType('hidden');
        }
        setOpenSetAddressDetails(true);
    };
    const handleCloseAddressDetails = () => 
    {
        setOpenSetAddressDetails(false);
    };
    const handleOk = () =>
    {


        setOpenSetAddressDetails(false);
    };
    ref.current = handleClickOpenAddressDetails;

    function doClose()
    {
        handleCloseAddressDetails();
    }

    const [_active,setActive] = useState("Yes");
    const [_defaultActive,setDefaultActive] = useState("Yes");

    const [_defaultType,setDefaultType] = useState("Vendor");
    const [_type,setType] = useState("Vendor");
    function updateType(event)
    {
        setType( event.target.value );
    }

    const [_defaultInActive,setDefaultInActive] = useState("");
    const [_inActive,setInActive] = useState("");
    const [_defaultState1,setDefaultState1] = useState("FL");
    const [_defaultState2,setDefaultState2] = useState("FL");
    function onActiveChange(event)
    {
        setActive( event.target.value );
    }

    const [_1099,set1099] = useState('');
    function update1099(event)
    {
        set1099( event.target.value );
    }

    const [_phoneFormat,setPhoneFormat] = useState('');
    function updatePhoneFormat(event)
    {
        setPhoneFormat( event.target.value );
    }

    const [_department,setDepartment] = useState('');
    function updateDepartment(event)
    {
        setDepartment( event.target.value );
    }

    const [_addrId,setAddrId] = useState('');
    function updateAddrId(event)
    {
        setAddrId( event.target.value );
    }

    const [_projectName,setProjectName] = useState('');
    function updateProjectName(event)
    {
        setProjectName( event.target.value );
    }

    const [_vendorAccount,setVendorAccount] = useState('');
    function updateVendorAccount(event)
    {
        setVendorAccount( event.target.value );
    }

    const [_vendorPolicy,setVendorPolicy] = useState('');
    function updateVendorPolicy(event)
    {
        setVendorPolicy( event.target.value );
    }

    const [_leadTime,setLeadTime] = useState('');
    function updateLeadTime(event)
    {
        setLeadTime( event.target.value );
    }

    const [_minimum,setMinimum] = useState('');
    function updateMinimum(event)
    {
        setMinimum( event.target.value );
    }

    const [_addressL1,setAddressL1] = useState('');
    function updateAddressL1(event)
    {
        setAddressL1( event.target.value );
    }

    const [_addressR1,setAddressR1] = useState('');
    function updateAddressR1(event)
    {
        setAddressR1( event.target.value );
    }

    const [_addressL2,setAddressL2] = useState('');
    function updateAddressL2(event)
    {
        setAddressL2( event.target.value );
    }

    const [_addressR2,setAddressR2] = useState('');
    function updateAddressR2(event)
    {
        setAddressR2( event.target.value );
    }

    const [_city1,setCity1] = useState('');
    function updateCity1(event)
    {
        setCity1( event.target.value );
    }

    const [_state1,setState1] = useState('');
    function updateState1(event)
    {
        setState1( event.target.value );
    }

    const [_zip1,setZip1] = useState('');
    function updateZip1(event)
    {
        setZip1( event.target.value );
    }

    const [_city2,setCity2] = useState('');
    function updateCity2(event)
    {
        setCity2( event.target.value );
    }

    const [_state2,setState2] = useState('');
    function updateState2(event)
    {
        setState2( event.target.value );
    }

    const [_zip2,setZip2] = useState('');
    function updateZip2(event)
    {
        setZip2( event.target.value );
    }

    const [_country, setCountry] = useState('');
    function updateCountry(event)
    {
        setCountry( event.target.value );
    }

    const [_phone, setPhone] = useState('');
    function updatePhone(event)
    {
        setPhone( event.target.value );
    }

    const [_fax, setFax] = useState('');
    function updateFax(event)
    {
        setFax( event.target.value );
    }

    const [_website, setWebsite] = useState('');
    function updateWebsite(event)
    {
        setWebsite( event.target.value );
    }

    const [_email, setEmail] = useState('');
    function updateEmail(event)
    {
        setEmail( event.target.value );
    }

    const [_projInstallDate,setProjInstallDate] = useState('');
    function updateProjInstallDate( event )
    {
        setProjInstallDate( event.target.value );
    }

    const [_projInstallDateVisible, setProjInstallDateVisible] = useState('none');
    const [_projInstallDateType, setProjInstallDateType] = useState('hidden');
    const [_editVisible, setEditVisible] = useState('block');
    const [_editVisibleType, setEditVisibleType] = useState('text');

    const [_loginId, setLoginId] = useState('');
    function updateLoginId(event)
    {
        setLoginId( event.target.value );
    }

    const [_notes, setNotes] = useState('');
    function updateNotes(event)
    {
        setNotes( event.target.value );
    }

    const [_billingName, setBillingName] = useState('');
    function updateBillingName(event)
    {
        setBillingName( event.target.value );
    }

    const [_installDate, setInstallDate] = useState('');
    function updateInstallDate(event)
    {
        setInstallDate( event.target.value );
    }

    const [_password, setPassword] = useState('');
    function updatePassword(event)
    {
        setPassword( event.target.value );
    }

    function doSave()
    {

        handleCloseAddressDetails();
    }

    const [_sayNoContacts, setNoContacts] = useState("No Contacts");
    const [tableData, setTableData] = useState([]);
    const [_contactName, setContactName] = useState('');
    const [_contactTitle, setContactTitle] = useState('');
    const [_contactPhone, setContactPhone] = useState('');
    const [_contactExtension, setContactExtension] = useState('');
    const [_contactEmail, setContactEmail] = useState('');

    function updateContactName( event )
    {
        setContactName( event.target.value );
    }
    function updateContactTitle( event )
    {
        setContactTitle( event.target.value );
    }
    function updateContactPhone( event )
    {
        setContactPhone( event.target.value );
    }
    function updateContactExtension( event )
    {
        setContactExtension( event.target.value );
    }
    function updateContactEmail( event )
    {
        setContactEmail( event.target.value );
    }

    function addContact()
    {
        let jsonArray = [];
        for( let i=0; i<tableData.length; i++ )
        {
            jsonArray.push( tableData[i] );
        }
        jsonArray.push(
            {name:_contactName,
            title:_contactTitle,
            phone:_contactPhone,
            extension:_contactExtension,
            email:_contactEmail
            }
        );
        setTableData( jsonArray );

        setContactName("");
        setContactTitle("");
        setContactPhone("");
        setContactExtension("");
        setContactEmail("");
        setNoContacts("");
    }

    const [_taxLocation,setTaxLocation] = useState('FL');
    function updateTaxLocation( event )
    {
        setTaxLocation( event.target.value );
    }
    const [_salesTax1,setSalesTax1] = useState('0');
    function updateSalesTax1( event )
    {
        setSalesTax1( event.target.value );
    }
    const [_salesTax2,setSalesTax2] = useState('0');
    function updateSalesTax2( event )
    {
        setSalesTax2( event.target.value );
    }

    const [_11,set11] = useState('0');
    const [_12,set12] = useState('0');
    const [_13,set13] = useState('0');
    const [_14,set14] = useState('0');
    const [_15,set15] = useState('0');
    const [_21,set21] = useState('0');
    const [_22,set22] = useState('0');
    const [_23,set23] = useState('0');
    const [_24,set24] = useState('0');
    const [_25,set25] = useState('0');
    const [_26,set26] = useState('0');
    const [_27,set27] = useState('0');
    function update11(event)
    {
        set11( event.target.value );
    }
    function update12(event)
    {
        set12( event.target.value );
    }
    function update13(event)
    {
        set13( event.target.value );
    }
    function update14(event)
    {
        set14( event.target.value );
    }
    function update15(event)
    {
        set15( event.target.value );
    }
    function update21(event)
    {
        set21( event.target.value );
    }
    function update22(event)
    {
        set22( event.target.value );
    }
    function update23(event)
    {
        set23( event.target.value );
    }
    function update24(event)
    {
        set24( event.target.value );
    }
    function update25(event)
    {
        set25( event.target.value );
    }
    function update26(event)
    {
        set26( event.target.value );
    }
    function update27(event)
    {
        set27( event.target.value );
    }

    const [_sidemark,setSidemark] = useState('');
    const [_accountNum,setAccountNum ] = useState('');
    const [_alert,setAlert ] = useState('');
    const [_federalID,setFederalID ] = useState('');
    const [_socialSec,setSocialSec ] = useState('');
    const [_FOB,setFOB ] = useState('');
    const [_attention,setAttention ] = useState('');
    function updateSidemark(event)
    {
        setSidemark( event.target.value );
    }
    function updateAccountNum(event)
    {
        setAccountNum( event.target.value );
    }
    function updateAlert(event)
    {
        setAlert( event.target.value );
    }
    function updateFederalID(event)
    {
        setFederalID( event.target.value );
    }
    function updateSocialSec(event)
    {
        setSocialSec( event.target.value );
    }
    function updateFOB(event)
    {
        setFOB( event.target.value );
    }
    function updateAttention(event)
    {
        setAttention( event.target.value );
    }

    const [_designerDropDown, setDesignerDropDown] = useState([]);
    const [_assistantDropDown, setAssistantDropDown] = useState([]);
    const [_termsDropDown, setTermsDropDown] = useState([]);
    const [_freightTermsDropDown, setFreightTermsDropDown] = useState([]);
    const [_specifierDropDown, setSpecifierDropDown] = useState([]);
    const [_shipViaDropDown, setShipViaDropDown] = useState([]);
    const [_cashValueDropDown, setCashDataDropDown] = useState([]);
    const [_cashValue, setCashValue] = useState('');
    const [_designerValue, setDesignerValue] = useState('');
    const [_assistantValue, setAssistantValue] = useState('');
    const [_termsValue, setTermsValue] = useState('');
    const [_freightTermsValue, setFreightTermsValue] = useState('');
    const [_specifierValue, setSpecifierValue] = useState('No');
    const [_shipViaValue, setShipViaValue] = useState('');
    const [_designerDropDownDefault, setDesignerDropDownDefault] = useState('');
    const [_assistantDropDownDefault, setAssistantDropDownDefault] = useState('');
    const [_termsDropDownDefault, setTermsDropDownDefault] = useState('');
    const [_freightTermsDropDownDefault, setFreightTermsDropDownDefault] = useState('');
    const [_specifierDropDownDefault, setSpecifierDropDownDefault] = useState('');
    const [_shipViaDropDownDefault, setShipViaDropDownDefault] = useState('');
    function updateDesignerValue(event)
    {
        setDesignerValue( event.target.value );
    }
    function updateTermsValue(event)
    {
        setTermsValue( event.target.value );
    }
    function updateFreightTermsValue(event)
    {
        setFreightTermsValue( event.target.value );
    }
    function updateAssistantValue(event)
    {
        setAssistantValue( event.target.value );
    }
    function updateSpecifierValue(event)
    {
        setSpecifierValue( event.target.value );
    }
    function updateShipViaValue(event)
    {
        setShipViaValue( event.target.value );
    }
    function updateCashValue(event)
    {
        setCashValue( event.target.value );
    }

    let setters = [
        setAddrId, "Address ID", '',
        setCountry, "Country", '',
        setDefaultType, "Type", '',
        setType, "Type", '',
        setDefaultActive, "Active", '',
        setActive, "Active", '',
        setDefaultInActive, "InActive Reason", '',
        setInActive, "InActive Reason", '',
        setProjectName, "Name", '',
        setAddressL1, "Address", '',
        setAddressL2, "Address 2", '',
        setCity1, "City", '',
        setDefaultState1, "State", '',
        setDefaultState2, "State", '',
        setZip1, "Zip", '',
        setBillingName, "Mailing Name", '',
        setAddressR1, "Mailing Address", '',
        setAddressR2, "Mailing Address 2", '',
        setCity2, "Mailing City", '',
        setZip2, "Mailing Zip", '',
        setPhone, "Phone", '',
        setFax, "Fax", '',
        setEmail, "Email", '',
        setWebsite, "Web Site", '',
        setLoginId, "Web Site ID", '',
        setPassword, "Password", '',
        setVendorAccount, "Vendor Account", '',
        setVendorPolicy, "Vendor Policy", '',
        setLeadTime, "Lead Time", '',
        setMinimum, "Minimum", '',
        setNotes, "Notes", '',
        setSidemark, "Client Sidemark", '',
        setAlert, "Alert", '',
        setFOB, "FOB", '',
        setAttention, "Attention", '',
        setAccountNum, "Account #", '',
        setFederalID, "Federal ID", '',
        setSocialSec, "Social Security #", '',
        setTaxLocation, "Tax Location", '',
        setSalesTax1, "Tax Percent", '',
        setSalesTax2, "Tax Percent 2", '',
        set12, "Mark Up Selling", '0',
        set22, "Mark Up Freight", '0',
        set13, "Mark Up Crating", '0',
        set14, "Mark Up Installation", '0',
        set15, "Mark Up Other", '0',
        set21, "Proposal Selling", '0',
        set22, "Proposal Mark Up", '0',
        set23, "Proposal Freight", '0',
        set24, "Proposal Crating", '0',
        set25, "Proposal Installation", '0',
        set26, "Proposal Other", '0',
        set27, "Proposal Sales Tax", '0',
        setInstallDate, "Install Date", ''
        ];

return(
    <Dialog
        open={openSetAddressDetails}
        onClose={handleCloseAddressDetails}

        fullWidth
        maxWidth="md"
    
        >
        <DialogTitle>
            <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>{_title}</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseAddressDetails}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>
        </DialogTitle>
        <DialogContent>

            <div id="details">
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#details"><b>Details</b></a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#contacts">Contacts</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#codes">Codes</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#salesTax">Sales Tax</a></div>
            </div>
            <br />

            <table>

                <tr style={{backgroundColor:'#66ff66'}}>
                    <td align="right">
                        Active:
                    </td>
                    <td>
                        <select value={_active} defaultValue={_defaultActive} onChange={onActiveChange}><option selected value="Yes">Yes</option><option value="No">No</option></select>
                        &nbsp;
                        <select value={_inActive} defaultValue={_defaultInActive} ><option value="" selected></option><option value="Closed">Closed</option><option value="Credit Issues">Credit Issues</option><option value="Negative Reviews">Negative Reviews</option><option value="Other">Other</option></select>
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td align="right">
                        Address ID:
                    </td>
                    <td>
                        <input onChange={updateAddrId} id="addrId" style={{width:120}} type="text" value={_addrId} />
                        &nbsp;&nbsp;&nbsp;Type:
                        <select value={_type} defaultValue={_defaultType} onChange={updateType}>
                            <option value="Client">Client</option>
                            <option value="Project" selected>Project</option>
                            <option value="Vendor">Vendor</option>
                            <option value="Office">Office</option>
                            <option value="Retail">Retail</option>
                            <option value="Showroom">Showroom</option>
                            <option value="Mfg">Mfg</option>
                            <option value="Ship To">Ship To</option>
                            <option value="Prospect">Prospect</option>
                            <option value="Personal">Personal</option>
                            <option value="Other">Other</option>
                        </select>
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td align="right">
                        Project Name:
                    </td>
                    <td>
                        <input onChange={updateProjectName} id="projectName" style={{width:250}} type="text" value={_projectName} />
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td align="right" style={{backgroundColor:'#66ff66'}}>Billing Name:</td>
                    <td style={{backgroundColor:'#66ff66'}}>
                        <input onChange={updateBillingName} id="billingName" style={{width:250}} type="text" value={_billingName} />    
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        Address:
                    </td>
                    <td>
                        <input onChange={updateAddressL1} id="addressL1" style={{width:250}} type="text" value={_addressL1} />
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td align="right" style={{backgroundColor:'#66ff66'}}>Address:</td>
                    <td style={{backgroundColor:'#66ff66'}}>
                        <input onChange={updateAddressR1} id="addressR1" style={{width:250}} type="text" value={_addressR1} />    
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        &nbsp;
                    </td>
                    <td>
                        <input onChange={updateAddressL2} id="addressL2" style={{width:250}} type="text" value={_addressL2} />
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td align="right" style={{backgroundColor:'#66ff66'}}>&nbsp;</td>
                    <td style={{backgroundColor:'#66ff66'}}>
                        <input onChange={updateAddressR2} id="addressR2" style={{width:250}} type="text" value={_addressR2} />    
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        City, State, Zip:
                    </td>
                    <td>
                        <input onChange={updateCity1} id="city1" style={{width:100}} type="text" value={_city1} />
                        &nbsp;
                        <select defaultValue={_defaultState1} onChange={updateState1} id="state" value={_state1} style={{width:60}}>
                                    <option value="" selected></option>
                                    <option selected value="FL">FL</option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                </select>
                        &nbsp;
                        <input onChange={updateZip1} id="city1" style={{width:80}} type="text" value={_zip1} />
                    </td>

                    <td style={{width:'20px'}}>&nbsp;</td>

                    <td align="right" style={{backgroundColor:'#66ff66'}}>C/S/Z:</td>

                    <td style={{backgroundColor:'#66ff66'}}>
                        <input onChange={updateCity2} id="city1" style={{width:100}} type="text" value={_city2} />
                        &nbsp;
                        <select defaultValue={_defaultState2} onChange={updateState2} id="state" value={_state2}>
                                    <option value=""></option>
                                    <option selected value="FL">FL</option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                </select>
                        &nbsp;
                        <input onChange={updateZip2} id="city2" style={{width:80}} type="text" value={_zip2} />
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        Country:
                    </td>
                    <td>
                    <select onChange={updateCountry} id="country" defaultValue={_country} value={_country} style={{width:248}}>
                                    <option value="US" selected>United States</option>
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Åland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia, Plurinational State of</option>
                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">British Indian Ocean Territory</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curaçao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Territories</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard Island and McDonald Islands</option>
                                    <option value="VA">Holy See (Vatican City State)</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran, Islamic Republic of</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                    <option value="KR">Korea, Republic of</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Lao People's Democratic Republic</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia, Federated States of</option>
                                    <option value="MD">Moldova, Republic of</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">Northern Mariana Islands</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Réunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthélemy</option>
                                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin (French part)</option>
                                    <option value="PM">Saint Pierre and Miquelon</option>
                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome and Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syrian Arab Republic</option>
                                    <option value="TW">Taiwan, Province of China</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania, United Republic of</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks and Caicos Islands</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="UM">United States Minor Outlying Islands</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands, British</option>
                                    <option value="VI">Virgin Islands, U.S.</option>
                                    <option value="WF">Wallis and Futuna</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </select>
                    </td>

                    <td style={{width:'20px'}}>&nbsp;</td>

                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td align="right">
                        Phone:
                    </td>
                    <td>
                        <input onChange={updatePhone} id="phone" style={{width:110}} type="text" value={_phone} />
                        &nbsp;&nbsp;&nbsp;Fax:
                        <input onChange={updateFax} id="fax" style={{width:100}} type="text" value={_fax} />
                    </td>

                    <td style={{width:'20px'}}>&nbsp;</td>

                    <td align="right" style={{backgroundColor:'#ffff88'}}>Website:</td>

                    <td style={{backgroundColor:'#ffff88'}}>
                        <input onChange={updateWebsite} id="website" style={{width:250}} type="text" value={_website} />
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        Email:
                    </td>
                    <td>
                        <input onChange={updateEmail} id="email" style={{width:250}} type="text" value={_email} />
                    </td>

                    <td style={{width:'20px'}}>&nbsp;</td>

                    <td align="right" style={{backgroundColor:'#ffff88'}}>Login ID:</td>

                    <td style={{backgroundColor:'#ffff88'}}>
                        <input onChange={updateLoginId} id="loginId" style={{width:250}} type="text" value={_loginId} />
                    </td>
                </tr>

                <tr>
                    <td align="right">
                        <span style={{display: _projInstallDateVisible}}>Proj Install Date:</span>
                    </td>
                    <td>
                        <input onChange={updateProjInstallDate} id="projInstallDate" style={{width:250}} type={_projInstallDateType} value={_projInstallDate} />
                    </td>

                    <td style={{width:'20px'}}>&nbsp;</td>

                    <td align="right" style={{backgroundColor:'#ffff88'}}>Password:</td>

                    <td style={{backgroundColor:'#ffff88'}}>
                        <input onChange={updatePassword} id="password" style={{width:250}} type={_editVisibleType} value={_password} />
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <table>
                            <tr>
                                <td align="right">
                                <span style={{display: _editVisible}}>Vendor Acct #:</span>
                                </td>
                                <td>
                                    <input onChange={updateVendorAccount} id="vendorAccount" style={{width:250}} type={_editVisibleType} value={_vendorAccount} />
                                </td>
                            </tr>
                            <tr>
                                <td align="right">
                                    <span style={{display: _editVisible}}>Vendor Policy:</span>
                                </td>
                                <td>
                                    <input onChange={updateVendorPolicy} id="vendorPolicy" style={{width:250}} type={_editVisibleType} value={_vendorPolicy} />
                                </td>
                            </tr>
                            <tr>
                                <td align="right">
                                    <span style={{display: _editVisible}}>Lead Time:</span>
                                </td>
                                <td>
                                    <input onChange={updateLeadTime} id="leadTime" style={{width:250}} type={_editVisibleType} value={_leadTime} />
                                </td>
                            </tr>
                            <tr>
                                <td align="right">
                                    <span style={{display: _editVisible}}>Minimum:</span>
                                </td>
                                <td>
                                    <input onChange={updateMinimum} id="minimum" style={{width:250}} type={_editVisibleType} value={_minimum} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td style={{width:'20px'}}>&nbsp;</td>
                    <td align="right">Notes:</td>
                    <td>
                        <textarea onChange={updateNotes} id="notes" rows="4" cols="50" style={{width:250}} type="text" value={_notes} />
                    </td>
                </tr>

            </table>


            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            <div id="contacts">
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#details"><b>Details</b></a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#contacts">Contacts</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#codes">Codes</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#salesTax">Sales Tax</a></div>
            </div>
            <br />

            {_sayNoContacts}

            <table style={{width: '727'}}>
            
            <tbody>
              {
                tableData.map((obj) => {
                  return (
                    <tr>
                      <td style={{width:169}}>
                        {obj.name}
                      </td>
                      <td style={{width:170}}>
                        {obj.title}
                      </td>
                      <td style={{width:170}}>
                        {obj.phone}
                      </td>
                      <td style={{width:48}}>
                        {obj.extension}
                      </td>
                      <td style={{width:170}}>
                        {obj.email}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
              <br />
          <table>
            <tr style={{backgroundColor:'#dddddd',padding:'5px'}}>
                <td>
                    <input placeholder="Name" onChange={updateContactName} id="contactName" style={{width:160}} type="text" value={_contactName} />
                </td>
                <td style={{width:10}}>
                    &nbsp;
                </td>
                <td>
                    <input placeholder="Title" onChange={updateContactTitle} id="contactTitle" style={{width:160}} type="text" value={_contactTitle} />
                </td>
                <td style={{width:10}}>
                    &nbsp;
                </td>
                <td>
                    <input placeholder="Phone" onChange={updateContactPhone} id="contactPhone" style={{width:160}} type="text" value={_contactPhone} />
                </td>
                <td style={{width:10}}>
                    &nbsp;
                </td>
                <td>
                    <input placeholder="Extension" onChange={updateContactExtension} id="contactExtension" style={{width:40}} type="text" value={_contactExtension} />
                </td>
                <td style={{width:10}}>
                    &nbsp;
                </td>
                <td>
                    <input placeholder="Email" onChange={updateContactEmail} id="contactEmail" style={{width:160}} type="text" value={_contactEmail} />
                </td>
                <td style={{width:10}}>
                    &nbsp;
                </td>
                <td>
                    <button onClick={addContact} type="button" style={{backgroundColor:'#66ff66'}}>Add</button>
                </td>
            </tr>
          </table>

    


            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            <div id="codes">
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#details"><b>Details</b></a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#contacts">Contacts</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#codes">Codes</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#salesTax">Sales Tax</a></div>
            </div>
            <br />

            <table style={{width:'100%'}}>
                <tr>
                    <td align="right">Designer:</td>
                    <td>
                        <select onChange={updateDesignerValue} value={_designerValue} defaultValue={_designerDropDownDefault} style={{width:150}} id="DropDownListAssistant">
                                {_designerDropDown.map((_designerDropDown) => 
                                    (
                                        <option value={_designerDropDown.value}>{_designerDropDown.label}</option>
                                ))}
                        </select>
                    </td>
                    <td colspan="2" style={{backgroundColor:'#dddddd'}}>{_column1}</td>
                    <td colspan="2" style={{backgroundColor:'#dddddd',display:_showSecondColumn}}>Client Proposal Deposit %</td>
                    <td align="right">Cash Account:</td>
                    <td>
                        <select onChange={updateCashValue} value={_cashValue} defaultValue={_cashValue} style={{width:150}} id="DropDownListCashValue">
                                {_cashValueDropDown.map((_cashValueDropDown) => 
                                    (
                                        <option value={_cashValueDropDown.value}>{_cashValueDropDown.label}</option>
                                ))}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td align="right">Sidemark:</td>
                    <td>
                        <input onChange={updateSidemark} id="sidemark" style={{width:150}} type="text" value={_sidemark} />
                    </td>
                    <td align="right">{_field1}</td>
                    <td>
                        <input onChange={update11} id="11" style={{width:40}} type="text" value={_11} />
                    </td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Selling %
                                </td>
                                <td>
                                    <input onChange={update12} id="21" style={{width:40}} type="text" value={_21} /> 
                                </td>
                            </tr>
                        </table>
                    </td>

                    <td align="right">Account #:</td>
                    <td>
                        <input onChange={updateAccountNum} id="accountNum" style={{width:150}} type="text" value={_accountNum} />
                    </td>
                </tr>
                <tr>
                    <td align="right">Specifier:</td>
                    <td>
                        <select onChange={updateSpecifierValue} value={_specifierValue} defaultValue={_specifierDropDownDefault} style={{width:150}} id="DropDownListAssistant">
                            <option value="No" selected>No</option>
                            <option value="Yes">Yes</option>
                      </select>
                    </td>
                    <td align="right">{_field2}</td>
                    <td>
                        <input onChange={update12} id="12" style={{width:40}} type="text" value={_12} />
                    </td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Mark Up %
                                </td>
                                <td>
                                    <input onChange={update22} id="12" style={{width:40}} type="text" value={_22} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td align="right">1099:</td>
                    <td>
                        <select value={_1099} defaultValue={_1099} onChange={update1099}><option value=""></option><option value="Yes">Yes</option><option value="No">No</option></select>
                    </td>
                </tr>
                <tr>
                    <td align="right">Alert:</td>
                    <td>
                        <input onChange={updateAlert} id="alert" style={{width:150}} type="text" value={_alert} />
                    </td>
                    
                    <td style={{display:_showSecondColumn}} align="right">Crating %</td>
                    <td style={{display:_showBlankFirstColumn}}>&nbsp;</td>
                    <td>
                        <input onChange={update13} id="13" style={{width:40}} type={_showFirstColumnInputs} value={_13} />&nbsp;
                    </td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Freight %
                                </td>
                                <td>
                                    <input onChange={update23} id="13" style={{width:40}} type="text" value={_23} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td align="right">Federal ID:</td>
                    <td>
                        <input onChange={updateFederalID} id="federalID" style={{width:150}} type="text" value={_federalID} />
                    </td>
                </tr>
                <tr>
                    <td align="right">Ship VIa:</td>
                    <td>
                        <select onChange={updateShipViaValue} value={_shipViaValue} defaultValue={_shipViaDropDownDefault} style={{width:150}} id="DropDownListAssistant">
                            {_shipViaDropDown.map((_shipViaDropDown) => 
                                (
                                    <option value={_shipViaDropDown.value}>{_shipViaDropDown.label}</option>
                            ))}
                      </select>
                    </td>
                    
                    <td style={{display:_showSecondColumn}} align="right">Installation %</td>
                    <td style={{display:_showBlankFirstColumn}}>&nbsp;</td>
                    <td>
                        <input onChange={update14} id="14" style={{width:40}} type={_showFirstColumnInputs} value={_14} />&nbsp;
                    </td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Crating %
                                </td>
                                <td>
                                    <input onChange={update24} id="24" style={{width:40}} type="text" value={_24} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td align="right">Social Sec #:</td>
                    <td>
                        <input onChange={updateSocialSec} id="socialSec" style={{width:150}} type="text" value={_socialSec} />
                    </td>
                </tr>
                <tr>
                    <td align="right">Terms:</td>
                    <td>
                        <select onChange={updateTermsValue} value={_termsValue} defaultValue={_termsDropDownDefault} style={{width:150}} id="DropDownListTerms">
                        {_termsDropDown.map((_termsDropDown) => 
                            (
                                <option value={_termsDropDown.value}>{_termsDropDown.label}</option>
                        ))}
                        </select>            
                    </td>

                    <td style={{display:_showSecondColumn}} align="right">Other %</td>
                    <td style={{display:_showBlankFirstColumn}}>&nbsp;</td>
                    <td>
                        <input onChange={update15} id="15" style={{width:40}} type={_showFirstColumnInputs} value={_15} />&nbsp;
                    </td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Installation %
                                </td>
                                <td>
                                    <input onChange={update25} id="25" style={{width:40}} type="text" value={_25} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td align="right">Phone Format:</td>
                    <td>
                        <select value={_phoneFormat} defaultValue={_phoneFormat} onChange={updatePhoneFormat}><option value="Yes">USA</option><option value="No">INT</option></select>
                    </td>
                </tr>
                <tr>
                    <td align="right">Freight Terms:</td>
                    <td>
                    <select onChange={updateFreightTermsValue} value={_freightTermsValue} defaultValue={_freightTermsDropDownDefault} style={{width:150}} id="DropDownListTerms">
                        {_freightTermsDropDown.map((_freightTermsDropDown) => 
                            (
                                <option value={_freightTermsDropDown.value}>{_freightTermsDropDown.label}</option>
                        ))}
                        </select>            
                    </td>
                    <td colspan="2">&nbsp;</td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Other %
                                </td>
                                <td>
                                    <input onChange={update26} id="26" style={{width:40}} type="text" value={_26} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td align="right">Department:</td>
                    <td>
                        <select value={_department} defaultValue={_department} onChange={updateDepartment}>
                            <option value="Not Specified">Not Specified</option>
                            <option value="Resort Designs">Resort Designs</option>
                            <option value="Model Merchandising">Model Merchandising</option>
                            <option value="Residential">Residential</option>
                            <option value="Corporate">Corporate</option>
                             <option value="Saturday Models">Saturday Models</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td align="right">FOB:</td>
                    <td>
                        <input onChange={updateFOB} id="FOB" style={{width:150}} type="text" value={_FOB} />
                    </td>
                    <td colspan="2">&nbsp;</td>

                    <td colspan="2" style={{display:_showSecondColumn}}>
                        <table>
                            <tr>
                                <td align="right" width="100">
                                    Sales Tax %
                                </td>
                                <td>
                                    <input onChange={update27} id="27" style={{width:40}} type="text" value={_27} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td align="right">Attention:</td>
                    <td>
                        <input onChange={updateAttention} id="attention" style={{width:150}} type="text" value={_attention} />
                    </td>
                    <td align="right"></td>
                    <td></td>
                    <td style={{display:_showSecondColumn}} align="right"></td>
                    <td style={{display:_showSecondColumn}}></td>
                    <td align="right">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td align="right">Assistant:</td>
                    <td>
                        <select onChange={updateAssistantValue} value={_assistantValue} defaultValue={_assistantDropDownDefault} style={{width:150}} id="DropDownListAssistant">
                            {_assistantDropDown.map((_assistantDropDown) => 
                                (
                                    <option value={_assistantDropDown.value}>{_assistantDropDown.label}</option>
                            ))}
                        </select>
                    </td>
                    <td align="right"></td>
                    <td></td>
                    <td style={{display:_showSecondColumn}} align="right"></td>
                    <td style={{display:_showSecondColumn}}></td>
                    <td align="right">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            </table>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            <div id="salesTax">
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#details"><b>Details</b></a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#contacts">Contacts</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#codes">Codes</a></div> &nbsp;
                <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#salesTax">Sales Tax</a></div>
            </div>
            <br />
            <b>Sales Tax</b><br />
            <table>
                <tr>
                    <td align="right">
                        Tax Location
                    </td>
                    <td>
                        <input onChange={updateTaxLocation} id="taxLocation" style={{width:150}} type="text" value={_taxLocation} />
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        Sales Tax % 1
                    </td>
                    <td>
                        <input onChange={updateSalesTax1} id="salesTax1" style={{width:150}} type="text" value={_salesTax1} />
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        Sales Tax % 2
                    </td>
                    <td>
                        <input onChange={updateSalesTax2} id="salesTax2" style={{width:150}} type="text" value={_salesTax2} />
                    </td>
                </tr>
                <tr>
                    <td colspan="2" align="center">
                        &nbsp;
                    </td>
                </tr>
            </table>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

        </DialogContent>

        <DialogActions>
            <button onClick={doSave} style={{width:'100px', height:'40px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <Button onClick={handleCloseAddressDetails}>
                Cancel
            </Button>
        </DialogActions>
        </Dialog>
        );
    
    });
    
    export default AddressDetails;
    