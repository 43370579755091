import React, { useEffect, useState, forwardRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';

const TransferCopy = forwardRef((props,ref) =>
{

	useEffect(() => 
	{
        getData();
	}, []);

    const [openTransferCopyItem, setOpenTransferCopyItem] = useState(false);
    const [_operation, setOperation] = useState("Copy");
    const [_clientProject, setClientProject] = useState([]);
    const [_clientValue,setClientValue] = useState('');

    async function getData()
    {
        let clientProject = await Helpers.getClientProject();
        clientProject = clientProject.clientProjectDetails;
        let jsonArray = [];
        for( let i=0; i<clientProject.length; i++ )
        {
            if( !Helpers.conforms( clientProject[i].Value ) )
            {
                continue;
            }
            jsonArray.push({value:clientProject[i].Value,label:clientProject[i].Text});
        }
        setClientProject( jsonArray );
    }

//#region AddBudgetItemCode

    const handleClickOpenTransferCopyItem = (operation) => 
    {
        setIconColor('#ffaaaa');
        setDisabled(false);
        setDisabled2(false);
        setOKButtonText("OK");
        setOperation( operation );
        setOpenTransferCopyItem(true);
    };

    const handleCloseTransferCopyItem = () => 
    {
        setOpenTransferCopyItem(false);
    };

    ref.current = handleClickOpenTransferCopyItem;
//#endregion

    let needRefresh = false;

    function handleOK()//const handleOk = () =>
    {
        if( _okButtonText.indexOf("items" ) >= 0 )
        {
            setOpenTransferCopyItem(false);
            if( _okButtonText.indexOf("transfer" ) >= 0 )
            {
                props.refreshMe();
            }
        }

        let _checkedItems = props.getListOfSelectedIds();

        if( _operation.toUpperCase() == "COPY" )
        {
            for( let i=0; i<_checkedItems.length; i++ )
            {
              Helpers.copy(_clientValue,_checkedItems[i]);
            }
            setOKButtonText("OK - " + _checkedItems.length + " items copied");
        }
        else
        {
            for( let i=0; i<_checkedItems.length; i++ )
            {
              Helpers.transfer(_clientValue,_checkedItems[i]);
            }
            setOKButtonText("OK - " + _checkedItems.length + " items transfered");
            needRefresh = true;
        }

        setDisabled(true);
        setDisabled2(true);
        setIconColor( '#aaaaaa');
    };

    function updateClientProject( event )
    {
        setClientValue( event.target.value );
    }

    const [_okButtonText,setOKButtonText] = useState('OK');
    const [_disabled, setDisabled] = useState(false);
    const [_disabled2, setDisabled2] = useState(false);
    const [_iconColor, setIconColor] = useState('#ffaaaa');

    return(
<Dialog 
    open={openTransferCopyItem}
    onClose={handleCloseTransferCopyItem}
    >
    <DialogTitle>

        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'70%'}}>
                        <span style={{fontSize:25}}>{_operation} Item</span>
                    </td>
                    <td style={{width:'30%'}} align="right">
                        <IconButton disabled={_disabled2} style={{backgroundColor:`${_iconColor}`}} onClick={handleCloseTransferCopyItem}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent>
        Select Client/Project:<br />

        <select style={{width:250}} id="DropDownClientProject" onChange={updateClientProject}>
            {_clientProject.map((_clientProject) => 
            (
                <option value={_clientProject.value}>{_clientProject.label}</option>
            ))}
        </select>                    

    </DialogContent>
    <DialogActions>
        <div style={{float:'left'}}>
            <button onClick={handleOK} style={{width:'180px', height:'40px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">{_okButtonText}</button>
        </div>
        <hr />
        <Button onClick={handleCloseTransferCopyItem} disabled={_disabled}>
            Cancel
        </Button>
    </DialogActions>
</Dialog>
    );

});

export default TransferCopy;
