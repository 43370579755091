import React from 'react';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

function Menu()
{

    return(
        <div>
            <div style={{width:"100%", textAlign:"right", fontSize:"8pt", margin:"0px 0px 0px 0px", padding:"0px 0px 0px 0px", title:"Group Name"}} welcome Joe Smith King of England>
                &nbsp;&nbsp;
                <a href="MessagesList" target="_blank" id="msglnk" style={{cursor:"pointer"}}>
                    <img id="imsg" alt="" src="Images/msg.png" height="18" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="Default.aspx" style={{fontSize:"8pt"}}>Sign Out</a>
                &nbsp;&nbsp;&nbsp;
            </div>
        
            <div class="header-custom" style={{clear:"both"}}>
                <div style={{display:"inlineBlock", float:"left", padding:"1px 0px", zIndex:"99"}}>
                    <img alt="" src="Images/MasterSloganGroup.PNG" height="28" />
                </div>
                <ul>
                <li><a href="/" title="Pro Designer" style={{fontSize:"1.3rem", fontStyle:"italic"}}>Pro Designer</a></li>
                <li><a href="/home" title="Home">Home </a></li>
                <li><a href="/address" title="Library">Library </a></li>
                <li><a href="/project2" title="Projects">Projects </a></li>
                <li>|</li>
                <li><a href="/proposallist" title="Proposal">Proposal </a></li>
                <li><a href="/poList" style={{display:"none", visibility:"hidden"}} title="PO">PO </a></li>
                <li>|</li>
                <li><a href="/expedite" title="Expediting">Expediting </a></li>
                <li><a href="/receiving" title="Receiving">Receiving </a></li>
                <li>|</li>
                <li><a href="/install" title="Installs">Installs </a></li>
                <li><a href="/claims" title="Claims">Claims </a></li>
                <li><a href="/inventory" title="Inventory">Inventory </a></li>
                <li>|</li>
                <li><a href="/reports" title="Reports">Reports </a></li>
                <li><a href="/settings" title="Settings">Settings </a></li>
                <li><a href="/help" title="Help">Help </a></li>
            </ul>
            </div>
            <div id="LoadDialog" style={{display:"none", textAlign:"center"}}>
                <img alt="" src="spinnervlll.gif" />
            </div>
        
        </div>
        );
       
}

export default Menu;
