import React, { useEffect, useState, forwardRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';

const AddBudgetItem = forwardRef((props,ref) =>
{

	useEffect(() => 
	{
        processDropDowns();
	}, []);

//#region AddBudgetItemCode
    const [openAddBudgetItem, setOpenAddBudgetItem] = React.useState(false);

    const handleClickOpenAddBudgetItem = () => 
    {
        setOpenAddBudgetItem(true);
    };

    const handleCloseAddBudgetItem = () => 
    {
        setOpenAddBudgetItem(false);
    };

    function handleOK()//const handleOk = () =>
    {
        if( props.adder( _amount, "Budget for " + _budgetForDesc, _budgetForCode ) )
        {
            setOpenAddBudgetItem(false);
            props.refreshMe();
        }
    };

    const [_amount,setAmount] = useState(0);

    ref.current = handleClickOpenAddBudgetItem;
//#endregion

    const [_budgetReasonsDropDown, setBudgetReasonsDropDown] = useState([]);

    async function processDropDownJSONArray( setFunction, array, valueKey, textKey )
    {
        let d = array;//await JSON.parse( array );
        let jsonArray = [];

        for( let i=0; i<d.length; i++ )
        {
            let value = d[i][valueKey].trim();
            let label = d[i][textKey];
            let jsonObject = { value:value, label:label};
            if( value.length > 0 )
            {
                jsonArray.push( jsonObject );
            }
        }

        setFunction( jsonArray );

        return jsonArray;
    }

    async function processDropDowns()
    {
        let budgetReasonData = await Helpers.getBudgetReasons();
        let jsonArray = await processDropDownJSONArray( setBudgetReasonsDropDown, budgetReasonData.budgetReasonData, "Sales_Code", "Description" );
        setBudgetForDesc( jsonArray[0].label );
        setBudgetForCode( jsonArray[0].value );
        setBudgetReasonsDropDown( jsonArray );
    }

    const [_budgetForDesc, setBudgetForDesc] = useState('');
    const [_budgetForCode, setBudgetForCode] = useState('');
    function onChange( event )
    {
        setBudgetForDesc( event.target.label );
        setBudgetForCode( event.target.value );
    }

    return(
<Dialog 
    open={openAddBudgetItem}
    onClose={handleCloseAddBudgetItem}
    >
    <DialogTitle>
            <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'70%'}}>
                        <span style={{fontSize:25}}>Add Budget Item</span>
                    </td>
                    <td style={{width:'30%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseAddBudgetItem}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent>
        <table>
            <tr>
                <td>Budget for </td>
                <td>
                <select style={{width:250}} id="DropDownListBudgetReasons" onChange={onChange}>
                    {_budgetReasonsDropDown.map((_budgetReasonsDropDown) => 
                    (
                    <option value={_budgetReasonsDropDown.value}>{_budgetReasonsDropDown.label}</option>
                    ))}
                </select>                    

        </td>
        </tr>
        <tr><td colspan="2">&nbsp;</td></tr>
        <tr>
            <td>Amount </td>
            <td>
        <TextField
            value={_amount}
            label="Amount"
            onChange={(e) => 
            {
                setAmount(e.target.value);
            }}
        />
        </td>
        </tr>
        </table>
    </DialogContent>
    <DialogActions>
        <div style={{float:'left'}}>
            <button onClick={handleOK} style={{width:'100px', height:'40px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
        </div>
        <hr />
        <Button onClick={handleCloseAddBudgetItem}>
            Cancel
        </Button>
    </DialogActions>
</Dialog>
    );

});

export default AddBudgetItem;
