// ReportsPage.js
import React, { useEffect, useState } from 'react';
import MenuComponent from '../components/Menu';
import CustomButton from '../components/ReportButton';
import ModalComponent from '../components/ModalComponent';

const ReportsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    document.title = 'Reports';
  }, []);

  const openPopup = (url, width, height) => {
    const window_size = 'width=' + width + ',height=' + height;
    window.open(url, '_blank', window_size);
  };

  const handleOpenModal = (title) => {
    setModalTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitModal = () => {
    // Handle submit action here
    alert('Form submitted');
    setModalOpen(false);
  };

  var st = require('../checkSessionTimeout.js');
  if (st.checkSession()) {
    window.location.href = '/';
  }

  return (
    <div>
      <MenuComponent />
      <h3 style={{ color: 'black', marginLeft: '10px' }}>Reports</h3>
      <div style={{ display: 'flex', padding: '50px 0 20px 16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '13px' }}>
          <CustomButton title="Project Worksheet" color="#198754" textColor="white" onClick={() => openPopup('http://prodesigner/ProjectWorksheet.aspx', 600, 400)} />
          <CustomButton title="Project Expediting" color="#198754" textColor="white" onClick={() => openPopup('http://prodesigner/ProjectExpediting.aspx', 600, 400)} />
          <CustomButton title="Reminders" color="#198754" textColor="white" onClick={() => openPopup('http://prodesigner/MessagesList', 1000, 1000)} />
          <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '5px' }}>
            <CustomButton title="Item Count" color="#198754" textColor="white" onClick={() => handleOpenModal('Item Count')} />
            <CustomButton title="Order Count" color="#198754" textColor="white" onClick={() => handleOpenModal('Order Count')} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          <CustomButton title="Security Log" color="#0DCAF0" textColor="black" onClick={() => openPopup('http://prodesigner/SecurityLog.aspx?id=s', 1000, 1000)} />
          <CustomButton title="Audit Log" color="#0DCAF0" textColor="black" onClick={() => openPopup('http://prodesigner/AuditLog.aspx?id=a', 1000, 1000)} />
        </div>
      </div>
      <ModalComponent
        title={modalTitle}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
    </div>
  );
};

export default ReportsPage;
