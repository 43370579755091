import React, { useState, forwardRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';

const AddThing = forwardRef((props,ref) =>
{
    const [openSetAddThing, setOpenSetAddThing] = React.useState(false);
    const [thing, setThing] = React.useState('');
    const [_obj, setObj] = React.useState({});
    let c = '';
    let _selectObj;

    const handleClickOpenAddThingItem = (which,obj) => 
    {
        _selectObj = obj;
        c = which;
        setThing( c );
        setObj( obj );
        setOpenSetAddThing(true);
    };
    const handleCloseAddThingItem = () => 
    {
        setOpenSetAddThing(false);
    };
    const handleOk = () =>
    {
        let len = _obj.options.length;
        let option = document.createElement("option");
        option.text = thingDescription;
        _obj.appendChild(option);
        _obj.selectedIndex = len;

        let uc = thing.toUpperCase();
        if( uc == "ROOM" )
        {
            Helpers.addRoom(thingId,thingDescription);
        }
        else if( uc == "SHIP VIA")
        {
            Helpers.addShip(thingId);
        }
        else if( uc == "TERMS" )
        {
            Helpers.addTerm( thingId );
        }
        else
        {
            let userData = localStorage.getItem('user_data');
            let ud = JSON.parse(userData);
            Helpers.addUnit( thingId, thingDescription,ud.employeeName);
        }

        setOpenSetAddThing(false);
    };
    ref.current = handleClickOpenAddThingItem;

	useEffect(() => 
	{
        setThing( c );
	}, []);

    const [thingId, setThingId] = useState('');
    const [thingDescription, setThingDescription] = useState('');

return(
    <Dialog
        open={openSetAddThing}
        onClose={handleCloseAddThingItem}
        >
        <DialogTitle>
            <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>Add {thing}</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseAddThingItem}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

        </DialogTitle>
        <DialogContent sx={{height:210, width:250}} >

        {`${thing} ID`}<br /><TextField id="thingId" label={`${thing} ID`}
                onChange={e=>{setThingId(e.target.value)}} />

        <br /><br />

        {`${thing} Description`}<br /><TextField id="thingText" label={`${thing} Description`}
                onChange={e=>{setThingDescription(e.target.value)}} />

        </DialogContent>

        <DialogActions>

            <button onClick={handleOk} style={{width:'100px', height:'40px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <Button autoFocus onClick={handleCloseAddThingItem}>
                Cancel
            </Button>

        </DialogActions>
    </Dialog>
        );
    
    });
    
    export default AddThing;
    