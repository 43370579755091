import React, { useEffect, useState } from 'react';

const DefaultPage = () =>
{
	useEffect(() => 
	{
		document.title = 'Pro Designer';
	}, []);

	let loginName = '';
	let loginPassword = '';

	const [message,setMessage] = useState('');

	const doLogin = async event => 
	{
		event.preventDefault();

		var bp = require('../Path.js');
  
		var obj = {login:loginName.value,password:loginPassword.value};
		var js = JSON.stringify(obj);
  
		try
		{    
			const response = await fetch(bp.buildPath('api/login'),
				{method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
  
			var res = JSON.parse(await response.text());
  
			if( res.error.toUpperCase().indexOf('NO RECORDS FOUND') >= 0 )
			{
				incrementIncorrectLogins();
			}
			else
			{
				localStorage.setItem('user_data', JSON.stringify(res));

				var st = require('../checkSessionTimeout.js');
				st.resetSession();
  
				setMessage('');
				window.location.href = '/home';
			}
		}
		catch(e)
		{
			alert(e.toString());
			return;
		}    
	};
	
	function incrementIncorrectLogins()
	{
		let currentMessage = message;
		if( currentMessage.length === 0 )
		{
			setMessage('INCORRECT USERNAME OR PASSWORD! This is failed attempt 1');
		}
		else
		{
			let index = currentMessage.length - 1;
			let value = 1;
			while( index > 0 && currentMessage[index] !== ' ' )
			{
				index--;
			}
			if( index > 0 )
			{
				let num = currentMessage.substring(index+1);
				value = parseInt( num );
			}
			value++;
			setMessage('INCORRECT USERNAME OR PASSWORD! This is failed attempt ' + value);
		}
	}

	return(
		<div style={{width:"100%",marginTop:"10px"}}>

			<div style={{width:"auto",float:"left",minHeight:"400px"}}>
				<h1>Pro Designer</h1>
				<span style={{fontWeight:"bold",fontStyle:"italic",fontStretch:"expanded",marginLeft:"20px"}}>Exceptional Designing for Exceptional Spaces</span>
				<br />
				<div style={{width:"100%",textAlign:"center",paddingTop:"50px"}}>
					<img alt="" id="Img01" src="Images/HomeBG2.jpg" width="500" />
				</div>
			</div>

			<div style={{float:"left",width:"auto",minHeight:"400px",height:"600px",borderLeft:"1px solid steelblue",backgroundColor:"white"}}>
				<div style={{textDecoration:"underline",fontWeight:" bold",fontSize:"1.5em"}}>Login</div>
				<div style={{width:"120px"}}>Login ID:</div>
				<div>
					<input autoFocus type="text" ID="TextBoxUserName" placeholder="Username" ref={(c) => loginName = c} />
				</div>
				<div style={{width:"120px"}}>Login Password:</div>
				<div>
					<input type="password" ID="TextBoxUserPassword" placeholder="Password" ref={(c) => loginPassword = c} />
				</div>
				<div>
					<button type="button" ID="ButtonSignIn" onClick={doLogin} style={{fontSize:"Larger",borderStyle:"Solid",borderColor:"SteelBlue",color:"White",backgroundColor:"SteelBlue"}}>Sign In</button>
				</div>
				<div>
					<span ID="message" style={{color:"red"}}>{message}</span>
				</div>
			</div>
		</div>
	);
};

export default DefaultPage;
