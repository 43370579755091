import React, { useMemo, useRef, useEffect, useState } from 'react';
import MenuComponent from '../components/Menu';
import Helpers from './project2Helpers';
import AddThing from '../components/AddThing';
import { useFilePicker } from 'use-file-picker';
import { saveAs } from 'file-saver';

const ProjectDetails = () =>
{

    const childRef = useRef(null);
    const childRef2 = useRef(null);

    const queryParameters = new URLSearchParams(window.location.search);
    let clientId = queryParameters.get("clientId");
    if( clientId == null || clientId.trim().length == 0 )
    {
        clientId = localStorage.getItem("ClientId");
    }
    const itemId = queryParameters.get("id");
    const action = queryParameters.get("action");
    const _new = queryParameters.get("new");
    const _projectid = queryParameters.get("projectid");
    const _ds = queryParameters.get("ds");
    const _as = queryParameters.get("as");

    let userData = localStorage.getItem('user_data');
    let ud = JSON.parse(userData);

	useEffect(() => 
	{
		document.title = 'Project Details';
        processDropDowns();
        setClientId( clientId );
        if( itemId != null )
        {
            setIdealItem( itemId );
        }
	}, []);

    function doClose()
    {
        window.location.href = "/Project2?clientid=" + clientId;
    }

    async function getItem( itemId )
    {
        let item = await Helpers.getItem( itemId );
        localStorage.setItem( "Project", item.Project );
    }

    var st = require('../checkSessionTimeout.js');
    if( st.checkSession() )
    {
        window.location.href = '/';
    }

    const [_roomsDropDown, setRoomsDropDown] = useState([]);
    const [_vendorDropDown, setVendorDropDown] = useState([]);
    const [_shipToDropDown, setShipToDropDown] = useState([]);
    const [_salesCodeDropDown, setSalesCodeDropDown] = useState([]);
    const [_designerDropDown, setDesignerDropDown] = useState([]);
    const [_assistantDropDown, setAssistantDropDown] = useState([]);
    const [_viaDropDown, setViaDropDown] = useState([]);
    const [_clientId, setClientId] = useState(clientId);
    const [_shipViaDropDown, setShipViaDropDown] = useState([]);
    const [_termsDropDown, setTermsDropDown] = useState([]);
    const [_unitDropDown, setUnitDropDown] = useState([]);

    const [_proposalValue, setProposalValue] = useState('');
    const [_poValue, setPOValue] = useState('');
    const [_needByDateValue, setNeedByDateValue] = useState('');
    const [_installDateValue, setInstallDateValue] = useState('');
    const [_expeditingNotesValue, setExpeditingNotesValue] = useState('');
    const [_sidemarkValue, setSidemarkValue] = useState('');
    const [_descriptionValue, setDescriptionValue] = useState('');

    const [_quantityValue1, setQuantityValue1] = useState('');
    const [_quantityValue2, setQuantityValue2] = useState('');
    const [_unitCostValue1, setUnitCostValue1] = useState('');
    const [_unitCostValue2, setUnitCostValue2] = useState('');
    const [_subTotalValue1, setSubTotalValue1] = useState('');
    const [_subTotalValue2, setSubTotalValue2] = useState('');
    const [_freightValue, setFreightValue] = useState('');
    const [_cratingValue, setCratingValue] = useState('');
    const [_installValue, setInstallValue] = useState('');
    const [_otherValue, setOtherValue] = useState('');
    const [_salesTaxValue, setSalesTaxValue] = useState('');
    const [_totalValue, setTotalValue] = useState('');

    const [_roomsDropDownDefault, setRoomsDropDownDefault] = useState('');
    const [_vendorDropDownDefault, setVendorDropDownDefault] = useState('');
    const [_shipToDropDownDefault, setShipToDropDownDefault] = useState('');
    const [_salesCodeDropDownDefault, setSalesCodeDropDownDefault] = useState('');
    const [_designerDropDownDefault, setDesignerDropDownDefault] = useState('');
    const [_assistantDropDownDefault, setAssistantDropDownDefault] = useState('');
    const [_shipViaDropDownDefault, setShipViaDropDownDefault] = useState('');
    const [_termsDropDownDefault, setTermsDropDownDefault] = useState('');
    const [_unitDropDownDefault, setUnitDropDownDefault] = useState('');
    const [_clientIdDefault, setClientIdDefault] = useState(clientId);

    const [_roomsValue, setRoomsValue] = useState('');
    const [_vendorValue, setVendorValue] = useState('');
    const [_shipToValue, setShipToValue] = useState('');
    const [_salesCodeValue, setSalesCodeValue] = useState('');
    const [_designerValue, setDesignerValue] = useState('');
    const [_assistantValue, setAssistantValue] = useState('');
    const [_shipViaValue, setShipViaValue] = useState('');
    const [_termsValue, setTermsValue] = useState('');
    const [_unitValue, setUnitValue] = useState('');

    function updateRoomsValue( event )
    { 
        setRoomsValue( event.target.value ); 
        let sidemark = clientId + " / " + event.target.value;
        setSidemarkValue( sidemark );
    }
    function updateVendorValue( event )
    { 
        setVendorValue( event.target.value ); 
    }
    function updateShipToValue( event )
    { 
        setShipToValue( event.target.value ); 
    }
    function updateSalesCodeValue( event )
    { 
        setSalesCodeValue( event.target.value ); 

        // Here we will set some values in fields. (RICK_)
        let newCarValue = event.target.value;
        // _idealItem
        // _clientId

    }
    function updateDesignerValue( event )
    { 
        setDesignerValue( event.target.value ); 
    }
    function updateAssistantValue( event )
    { 
        setAssistantValue( event.target.value ); 
    }
    function updateShipViaValue( event )
    { 
        setShipViaValue( event.target.value ); 
    }
    function updateTermsValue( event )
    { 
        setTermsValue( event.target.value ); 
    }
    function updateUnitValue( event )
    { 
        setUnitValue( event.target.value ); 
    }
    
    const [_itemDescriptionTable, setItemDescriptionTable] = useState('<table><tr><td>Test Me</td></tr></table>');

    function processDropDownJSONArray( setFunction, array, valueKey, textKey )
    {
        let d = JSON.parse( array );
        let jsonArray = [];

//        jsonArray.push( { value:'<select>', label:'<select>'} );
        jsonArray.push( { value:'', label:''} );

        for( let i=0; i<d.length; i++ )
        {
            let value = d[i][valueKey].trim();
            let label = d[i][textKey];
            let jsonObject = { value:value, label:label};
            if( value.length > 0 )
            {
                jsonArray.push( jsonObject );
            }
        }

        setFunction( jsonArray );

        return jsonArray;
    }

    function setDropDownDefault( setFunction, setFunction2, json, key )
    {
        try
        {
            let v = json[key];
            if( v == null || v.trim().length <= 0 )
            {
//                setFunction( "<select>" );
                setFunction( "" );
                setFunction2( "" );

                console.log( key );
                console.log( "(empty)" );
            }
            else
            {
                setFunction( v );
                setFunction2( v );

                console.log( key );
                console.log( v );
            }
        }
        catch(e){}
    }

    async function createNewItem()
    {
        await Helpers.createNewItem(clientId,ud.employeeName,"LJ",ud.employeeName,'12/12/2024');
    }

    const [_idealItem, setIdealItem ] = useState();
    const [_dept, setDepartment ] = useState();
    const [_PONo,setPONo] = useState();
    const [_status,setStatus] = useState();
    const [_EXPStatus,setEXPStatus] = useState();
    const [_RCVStatus,setRCVStatus] = useState();

    async function processDropDowns()
    {
        let dropDownData = await Helpers.callGetDropDownsAPI();
        processDropDownJSONArray( setRoomsDropDown, dropDownData.roomData, "RoomValue", "RoomText" );
        processDropDownJSONArray( setVendorDropDown, dropDownData.vendorData, "Value", "Text" );
        processDropDownJSONArray( setShipToDropDown, dropDownData.shipToData, "Value", "Text" );
        processDropDownJSONArray( setSalesCodeDropDown, dropDownData.salesCodeData, "Value", "Text" );
        processDropDownJSONArray( setDesignerDropDown, dropDownData.designerData, "Value", "Text" );
        processDropDownJSONArray( setAssistantDropDown, dropDownData.assistantData, "Value", "Text" );
        processDropDownJSONArray( setShipViaDropDown, dropDownData.viaData, "Value", "Text" );
        processDropDownJSONArray( setTermsDropDown, dropDownData.termData, "Value", "Text" );
        processDropDownJSONArray( setUnitDropDown, dropDownData.unitData, "Value", "Text" );

        if( _new == 'yes' )
        {
            await createNewItem();
        }

        let d = await Helpers.callGetItem( itemId );
        if( d != null )
        {
            d = await JSON.parse( d.retData );
            if( d != null )
            {
                d = await d[0];

//                setDesignerValue( d["Designer"] );
//                setIdealItem( d["Ideal Item #"] );
//                setDepartment( d["Department"] );
//                setPONo(d["PO #"]);
//                setStatus( d["Status"] );
//                setEXPStatus( d["EXPStatus"] );
//                setRCVStatus( d["RCVStatus"] );
            
                setDropDownDefault( setRoomsDropDownDefault, setRoomsValue, d, "Room" );
                setDropDownDefault( setSalesCodeDropDownDefault, setSalesCodeValue, d, "Sales Code" );
                setDropDownDefault( setVendorDropDownDefault, setVendorValue, d, "Vendor" );
                setDropDownDefault( setShipToDropDownDefault, setShipToValue, d, "Ship To" );
                setDropDownDefault( setDesignerDropDownDefault, setDesignerValue, d, "Designer" );
                setDropDownDefault( setAssistantDropDownDefault, setAssistantValue, d, "Assistant" );
                setDropDownDefault( setShipViaDropDownDefault, setShipViaValue, d, "Ship Via" );
                setDropDownDefault( setTermsDropDownDefault, setTermsValue, d, "Terms" );
                setDropDownDefault( setUnitDropDownDefault, setUnitValue, d, "Unit" );

                processColumnTwoData( d );
                processColumnThreeData( d );
            }
        }

        let d1 = await Helpers.getItemDetail( itemId );
        if( d1 = null )
        {
            d1 = await JSON.parse( d1.itemDetailData );
            if( d1 != null )
            {
                buildItemDescriptionTable( d1 );
            }
        }

        if( d != null )
        {
            buildItemStatus( d );
        }

        let d2 = await Helpers.getItemMessages( itemId );
        if( d2 != null )
        {
            d2 = await JSON.parse( d2.itemMessageData );
            if( d2 != null )
            {
                buildMessages( d2 );
            }
        }

    }

    function buildMessages( json )
    {

    }

    const [_c1r1, setC1R1] = useState('');
    const [_c1r2, setC1R2] = useState('');
    const [_c1r3, setC1R3] = useState('');
    const [_c1r4, setC1R4] = useState('');
    const [_c1r5, setC1R5] = useState('');
    const [_c1r6, setC1R6] = useState('');
    const [_c1r7, setC1R7] = useState('');
    const [_c1r8, setC1R8] = useState('');
    const [_c1r9, setC1R9] = useState('');
    const [_c1r10, setC1R10] = useState('');
    const [_c2r1, setC2R1] = useState('');
    const [_c2r2, setC2R2] = useState('');
    const [_c2r3, setC2R3] = useState('');
    const [_c2r4, setC2R4] = useState('');
    const [_c2r7, setC2R7] = useState('');
    const [_c3r1, setC3R1] = useState('');
    const [_c3r2, setC3R2] = useState('');
    const [_c3r3, setC3R3] = useState('');
    const [_c3r4, setC3R4] = useState('');

    let doIt = [
        setC1R1,"Purchase Deposit Percent",
        setC1R2,"Purchase Deposit",
        setC1R3,"ACKNO",
        setC1R4,"Acknowledge Date",
        setC1R5,"Est Ship Date",
        setC1R6,"Date Shipped",
        setC1R7,"Received",
        setC1R8,"Delivered",
        setC1R9,"Installed",
        setC1R10,"RCVSTATUS",
        setC2R1,"Vendor Invoice #",
        setC2R2,"Vendor Invoice Date",
        setC2R3,"Vendor Invoice Due",
        setC2R4,"Check Amount",
        setC2R7,"Check Amount",
        setC3R1,"Entered By",
        setC3R2,"Entered Date",
        setC3R3,"Changed By",
        setC3R4,"Changed Date"
        ];
        
    function buildItemStatus( json )
    {
        //setTextField( setFunction, key, json )

        for( let i=0; i<doIt.length; i+= 2 )
        {
            try
            {
                setTextField(doIt[i], doIt[i+1], json );
            }
            catch(e){}
        }
    }

    function buildItemDescriptionTable(d)
    {
        let jsonArray = [];
        let no = 1;
        for( let i=0; i<d.length; i++ )
        {
            let child = d[i];
            let desc = child["Description"];
            let line = child["Line #"];
            jsonArray.push( { no:no, description:desc, trash:line } );
            no++;
        }
        setTableData( jsonArray );
    }

// { Header: "No.", accessor: "no" },
// { Header: "Description", accessor: "description" },
// { Header: "Trash", accessor: "trash" }

    function setTextField( setFunction, key, json )
    {
        let txt = json[key];
        if( txt == null )
        {
            txt = "";
        }

        try
        {
            txt = txt.trim();
            setFunction( txt );
        }
        catch(e)
        {
            setFunction("");
        }
    }

    function processColumnTwoData( d )
    {
        setTextField( setProposalValue, "Proposal #", d );
        setTextField( setPOValue, "PO #", d );
        setTextField( setNeedByDateValue, "Date Requested", d );
        setTextField( setInstallDateValue, "InstallDate", d );
        setTextField( setExpeditingNotesValue, "Expediting", d );
        setTextField( setSidemarkValue, "Sidemark", d );
        setTextField( setDescriptionValue, "Item Description", d );
    }

    function setNumericDataInTextField( setFunction, key, json )
    {
        let txt = "0";

        try
        {
            let val = parseInt( json[key] );
            txt = "" + val;
        }
        catch(e){}

        setFunction( txt );
    }

    let USDollar = new Intl.NumberFormat('en-US', 
    {
        style: 'currency',
        currency: 'USD',
    });

    function setCurrencyDataInTextField( setFunction, key, json )
    {
        let val = 0;

        try
        {
            let val = parseInt( json[key] );
        }
        catch(e){}

        let txt = USDollar.format( val ).substring( 1 );

        setFunction( txt );
    }

    // const [_quantityValue1, setQuantityValue1] = useState('');
    // const [_quantityValue2, setQuantityValue2] = useState('');
    // const [_unitCostValue1, setUnitCostValue1] = useState('');
    // const [_unitCostValue2, setUnitCostValue2] = useState('');
    // const [_subTotalValue1, setSubTotalValue1] = useState('');
    // const [_subTotalValue2, setSubTotalValue2] = useState('');
    // const [_freightValue, setFreightValue] = useState('');
    // const [_cratingValue, setCratingValue] = useState('');
    // const [_installValue, setInstallValue] = useState('');
    // const [_otherValue, setOtherValue] = useState('');
    // const [_salesTaxValue, setSalesTaxValue] = useState('');
    // const [_totalValue, setTotalValue] = useState('');

    function processColumnThreeData( d )
    {
        setNumericDataInTextField(setQuantityValue1,"Purchase Quantity",d);
        setNumericDataInTextField(setQuantityValue2,"Budget Quantity",d);
        setCurrencyDataInTextField(setUnitCostValue1,"Purchase Unit Cost",d);
        setCurrencyDataInTextField(setUnitCostValue2,"Budget Unit Cost",d);
        setCurrencyDataInTextField(setSubTotalValue1,"Purchase Cost",d);
        setCurrencyDataInTextField(setSubTotalValue2,"Budget Cost",d);
        setCurrencyDataInTextField(setFreightValue,"Purchase Freight",d);
        setCurrencyDataInTextField(setCratingValue,"Purchase Crating",d);
        setCurrencyDataInTextField(setInstallValue,"Purchase Installation",d);
        setCurrencyDataInTextField(setOtherValue,"Purchase Other",d);
        setCurrencyDataInTextField(setSalesTaxValue,"Sales Tax",d);
        let total = 0;
        try
        {
            total += d["Purchase Unit Cost"];
            total += d["Purchase Freight"];
            total += d["Purchase Crating"];
            total += d["Purchase Installation"];
            total += d["Purchase Other"];
            total += d["Sales Tax"];
        }
        catch(e){}
        setTotalValue(USDollar.format( total ).substring( 1 ));
    }

    function addClickRoom( event )
    {
        let obj = document.getElementById("DropDownListRoom");
        childRef.current('Room',obj);
    }

    function addClickShipVia( event )
    {
        let obj = document.getElementById("DropDownListShipVia");
        childRef.current('Ship Via',obj);
    }

    function addClickTerms( event )
    {
        let obj = document.getElementById("DropDownListTerms");
        childRef.current('Terms',obj);
    }

    function addClickUnit( event )
    {
        let obj = document.getElementById("DropDownListUnit");
        childRef.current('Unit',obj);
    }

    // https://www.educative.io/answers/how-to-build-a-dynamic-table-with-react    

    const columns = useMemo(
        () => [
          { Header: "No.", accessor: "no" },
          { Header: "Description", accessor: "description" },
          { Header: "Trash", accessor: "trash" }
        ],
        []
      );

      const [tableData, setTableData] = useState([{no:1,description:"test 1",trash:99}]);

      const columns2 = useMemo(
        () => [
          { Header: "Employee", accessor: "Employee" },
          { Header: "Action Date", accessor: "Action_Date" },
          { Header: "Description", accessor: "Description" },
          { Header: "Date Entered", accessor: "Date_Entered" },
          { Header: "Entered By", accessor: "Entered_By" }
        ],
        []
      );

      const [messData, setMessData] = useState([{Employee:"No records found",Action_Date:"",Description:"",Date_Entered:"",Entered_By:""}]);

      function deleteItemDetail()
      {
        let msg = Helpers.getMessages( itemId );
        if( msg.length > 0 )
        {
            let jsonArray = [];
            for( let i=0; i<msg.length; i++ )
            {
                let Employee = msg[i].Employee;
                let Action_Date = msg[i].Action_Date;
                let Description = msg[i].Description;
                let Date_entered = msg[i].Date_Entered;
                let Entered_By = msg[i].Entered_By;
                jsonArray += {Employee:Employee,Action_Date:Action_Date,Description:Description,Date_Entered:Date_entered,Entered_By:Entered_By};
            }
            setMessData( jsonArray );
        }
        else
        {

        }

      }

      const { openFilePicker, filesContent, loading } = useFilePicker({
        accept: ['.jpg', '.png','*.*'],
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            // this callback is always called, even if there are errors
            console.log('onFilesSelected', plainFiles, filesContent, errors);
            setSelectedFile(filesContent[0].name);
            setSelectedFileContent(filesContent[0].content);

            let blob = new Blob(filesContent[0].content.split(''),{type:"data:image/png"});
            saveAs(blob, "c:\\work\\crap.png");//filesContent[0].name );
          },
      });

    const [_selectedFile, setSelectedFile] = useState('No File Selected');
    const [_selectedFileContent, setSelectedFileContent] = useState();

    async function doSave()
    {
        let err = await Helpers.saveDetails("DESIGNER",_roomsValue,_idealItem,"LJ",ud.employeeName,_vendorValue,_shipToValue,_quantityValue1,_salesCodeValue,_quantityValue2,
            _unitValue,_descriptionValue,"",_salesCodeValue,_dept,_PONo,_status,_EXPStatus,_RCVStatus,updateUnitCostValue1,updateSubTotalValue1,"","",
            updateUnitCostValue2,updateSubTotalValue2,_freightValue,_cratingValue,_installValue,_otherValue,_salesTaxValue,"","","",_shipViaValue,
            _termsValue, "", _priority, "", "", "", "", clientId);
        let error = err.error.trim();
        if( error.length > 0 )
        {
            alert( error );
        }
        else
        {
            window.location.href = "/Project2?clientid=" + clientId;
        }
    }

    function updateQuantityValue1(event) // Purchase Quantity
    {
        setQuantityValue1(event.target.value);
    }
    function updateQuantityValue2(event) // Budget Quantity
    {
        setQuantityValue2(event.target.value);
    }
    function updateUnitCostValue1(event) // Purchase Unit Cost
    {
        setUnitCostValue1(event.target.value);
    }
    function updateUnitCostValue2(event) // Budget Unit Cost
    {
        setUnitCostValue2(event.target.value);
    }
    function updateSubTotalValue1(event) // Purchase Cost
    {
        setSubTotalValue1(event.target.value);
    }
    function updateSubTotalValue2(event) // Budget Cost
    {
        setSubTotalValue2(event.target.value);
    }
    function updateFreightValue(event) // Purchase Freight
    {
        setFreightValue(event.target.value);
    }
    function updateCratingValue(event) // Purchase Crating
    {
        setCratingValue(event.target.value);
    }
    function updateInstallValue(event) // Purchase Installation
    {
        setInstallValue(event.target.value);
    }
    function updateOtherValue(event) // Purchase Other
    {
        setOtherValue(event.target.value);
    }
    function updateSalesTaxValue(event)  // Sales Tax
    {
        setSalesTaxValue(event.target.value);
    }
    function updateTotalValue(event)
    {
        setTotalValue(event.target.value);
    }
    
    //<option value="Info Completed" style={{backgroundColor:'green'}}>Info Completed</option><option value="Need Fab/Fin" style={{backgroundColor:'yellow'}}>Need Fab/Fin</option><option value="Priority" style={{backgroundColor:'red'}}>Priority</option><option value=""></option></select></td></tr>
    const [_priority,setThePriority] = useState(0);
    function setPriority( event )
    {
        let p = event.target.value;
        let priority = 0;
        if( p == "Need Fab/Fin" )
        {
            priority = 1;
        }
        else if( p == "Priority" )
        {
            priority = 2;
        }
        setThePriority( priority );
    }

    function updateIdealItem( event )
    {
        setIdealItem( event.target.value );
    }
    function updateProposalItem( event )
    {
        setProposalValue( event.target.value );
    }
    function updatePOItem( event )
    {
        setIdealItem( event.target.value );
    }
    function updateNeedByDateItem( event )
    {
        setNeedByDateValue( event.target.value );
    }
    function updateInstallDateItem( event )
    {
        setInstallDateValue( event.target.value );
    }
    function updateExpeditingNotesItem( event )
    {
        setExpeditingNotesValue( event.target.value );
    }
    function updateSidemarkItem( event )
    {
        setSidemarkValue( event.target.value );
    }

    return(
<div>
    <div id="projects">
        <MenuComponent />
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#projects"><b>Projects</b></a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#images">Images</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#status">Status</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#message">Messages</a></div> &nbsp;<div style={{float:'right'}}>
            <button onClick={doSave} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <button onClick={doClose} type="button">Close</button></div>
    </div>
    <div>
        <br />
<table>
<tr>
<td valign="top">
Projects

<table>
    <tr><td style={{padding:10}} align='right'>Priority:</td><td><select onChange={setPriority} id="priorty" style={{width:250, minHeight:'28px', borderRadius:'4px'}} tabindex="1"><option value="Info Completed" style={{backgroundColor:'green'}}>Info Completed</option><option value="Need Fab/Fin" style={{backgroundColor:'yellow'}}>Need Fab/Fin</option><option value="Priority" style={{backgroundColor:'red'}}>Priority</option><option value=""></option></select></td></tr>
    <tr><td style={{padding:10}} align='right'>Client:</td><td><input readonly id="tbClient" TabIndex="1" style={{width:250}} type="text" value={_clientId} /></td></tr>
    <tr><td style={{padding:10}} align='right'>Sales Code:</td><td><select onChange={updateSalesCodeValue} value={_salesCodeValue} defaultValue={_salesCodeDropDownDefault} style={{width:250}} id="DropDownListSalesCode">
        {_salesCodeDropDown.map((_salesCodeDropDown) => 
        (
          <option value={_salesCodeDropDown.value}>{_salesCodeDropDown.label}</option>
        ))}
        </select></td></tr>
    <tr><td style={{padding:10}} align='right'>Room:</td><td><select onChange={updateRoomsValue} value={_roomsValue} defaultValue={_roomsDropDownDefault} style={{width:250}} id="DropDownListRoom">
        {_roomsDropDown.map((_roomsDropDown) => 
        (
          <option value={_roomsDropDown.value}>{_roomsDropDown.label}</option>
        ))}
        </select> <img src={'Images/plus.png'} onClick={addClickRoom} /></td></tr>
    <tr><td style={{padding:10}} align='right'>Item Line #:</td><td><input id="tbItem" value={_idealItem} onChange={updateIdealItem} MaxLength="10" TabIndex="1" style={{width:250}} type="text" /></td></tr>
    <tr><td style={{padding:10}} align='right'>Vendor:</td><td><select onChange={updateVendorValue} value={_vendorValue} defaultValue={_vendorDropDownDefault} style={{width:250}} id="DropDownListVendor">
        {_vendorDropDown.map((_vendorDropDown) => 
        (
          <option value={_vendorDropDown.value}>{_vendorDropDown.label}</option>
        ))}
        </select></td></tr>
    <tr><td style={{padding:10}} align='right'>Ship To:</td><td><select onChange={updateShipToValue}  value={_shipToValue} defaultValue={_shipToDropDownDefault} style={{width:250}} id="DropDownListShipTo">
        {_shipToDropDown.map((_shipToDropDown) => 
        (
          <option value={_shipToDropDown.value}>{_shipToDropDown.label}</option>
        ))}
        </select></td></tr>
    <tr><td style={{padding:10}} align='right'>Designer:</td><td><select onChange={updateDesignerValue} value={_designerValue} defaultValue={_designerDropDownDefault} style={{width:250}} width="130" id="DropDownListDesigner">
        {_designerDropDown.map((_designerDropDown) => 
        (
          <option value={_designerDropDown.value}>{_designerDropDown.label}</option>
        ))}
        </select></td></tr>
    <tr><td style={{padding:10}} align='right'>Assistant</td><td><select onChange={updateAssistantValue} value={_assistantValue} defaultValue={_assistantDropDownDefault} style={{width:250}} id="DropDownListAssistant">
        {_assistantDropDown.map((_assistantDropDown) => 
        (
          <option value={_assistantDropDown.value}>{_assistantDropDown.label}</option>
        ))}
        </select></td></tr>
</table>
                </td>
                <td valign="top">
Status:                    
<table>
    <tr>
        <td style={{padding:10}} align='right'>
            Proposal #:
        </td>
        <td>
            <input onChange={updateProposalItem} value={_proposalValue} id="proposalNum" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            PO #:
        </td>
        <td>
            <input onChange={updatePOItem} value={_poValue} id="poNum" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Ship Via:
        </td>
        <td>
            <select onChange={updateShipViaValue} value={_shipViaValue} defaultValue={_shipViaDropDownDefault} style={{width:250}} id="DropDownListShipVia">
            {_shipViaDropDown.map((_shipViaDropDown) => 
            (
                <option value={_shipViaDropDown.value}>{_shipViaDropDown.label}</option>
            ))}
            </select>
            <img src={'Images/plus.png'} onClick={addClickShipVia} />            
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Terms:
        </td>
        <td>
        <select onChange={updateTermsValue} value={_termsValue} defaultValue={_termsDropDownDefault} style={{width:250}} id="DropDownListTerms">
            {_termsDropDown.map((_termsDropDown) => 
            (
                <option value={_termsDropDown.value}>{_termsDropDown.label}</option>
            ))}
            </select>            
            <img src={'Images/plus.png'} onClick={addClickTerms} />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Need By Date:
        </td>
        <td>
            <input onChange={updateNeedByDateItem} value={_needByDateValue} id="needByDate" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Install Date:
        </td>
        <td>
            <input onChange={updateInstallDateItem} value={_installDateValue} id="installDate" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Expediting Notes:
        </td>
        <td>
            <input onChange={updateExpeditingNotesItem} value={_expeditingNotesValue} id="expeditingNotes" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
    <tr>
        <td style={{padding:10}} align='right'>
            Sidemark:
        </td>
        <td>
            <input onCHange={updateSidemarkItem} value={_sidemarkValue} id="sidemark" MaxLength="10" TabIndex="1" style={{width:250}} type="text" />
        </td>
    </tr>
</table>         

</td>
<td valign="top">
Item Description:

<table>
    <tr><td colspan="3"><input value={_descriptionValue} id="TextBoxDesc" MaxLength="10" TabIndex="1" style={{width:290}} type="text" /></td></tr>

    <tr><td colspan="3">
        <div>
        <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Description</th>
            <th>Trash</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.map((obj) => {
              return (
                <tr>
                  <td>{obj.no}</td>
                  <td>{obj.description}</td>
                  <td><img src='Images/delete-red.png' onClick={deleteItemDetail} /></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
        </div>
    </td></tr>

    <tr><td><button style={{backgroundColor:'cyan'}} type="button">Add Line</button></td><td colspan="2">&nbsp;</td></tr>
    <tr><td colspan="3"><hr /></td></tr>
    <tr><td align='right'>Purchase:</td><td><button style={{backgroundColor:'green',color:'white'}} type="button">Go To Website</button></td><td>&nbsp;</td></tr>
    <tr><td align='right'>Unit:</td><td>
        <select onChange={updateUnitValue}  value={_unitValue}  defaultValue={_unitDropDownDefault} style={{width:140}} id="DropDownListUnit">
            {_unitDropDown.map((_unitDropDown) => 
            (
                <option value={_unitDropDown.value}>{_unitDropDown.label}</option>
            ))}
            </select>         
            <img src={'Images/plus.png'} onClick={addClickUnit} />   
        </td><td><b>Budget</b></td></tr>

    <tr style={{backgroundColor:'chiffon'}}>
        <td align='right'>Quantity:</td>
        <td><input onChange={updateQuantityValue1} value={_quantityValue1} id="quantity1" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td><input onChange={updateQuantityValue2} value={_quantityValue2} id="quantity2" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
    </tr>

    <tr style={{backgroundColor:'chiffon'}}>
        <td align='right'>Unit Cost:</td>
        <td><input onChange={updateUnitCostValue1} value={_unitCostValue1} id="unitCost1" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td><input onChange={updateUnitCostValue2} value={_unitCostValue2} id="unitCost2" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
    </tr>

    <tr>
        <td align='right'>Sub Total:</td>
        <td><input nChange={updateSubTotalValue1} value={_subTotalValue1} id="subTotal1" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td><input nChange={updateSubTotalValue2} value={_subTotalValue2} id="subTotal2" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
    </tr>

    <tr>
        <td align='right'>Freight:</td>
        <td><input onChange={updateFreightValue} value={_freightValue} id="freight" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td align='right'>Crating:</td>
        <td><input onChange={updateCratingValue} value={_cratingValue} id="crating" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td align='right'>Install:</td>
        <td><input onChange={updateInstallValue} value={_installValue} id="install" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td align='right'>Other:</td>
        <td><input onChange={updateOtherValue} value={_otherValue} id="other" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td align='right'>Sales Tax:</td>
        <td><input onChange={updateSalesTaxValue} value={_salesTaxValue} id="salesTax" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td align='right'>Total:</td>
        <td><input onChange={updateTotalValue} value={_totalValue} id="total" MaxLength="10" TabIndex="1" style={{width:140}} type="text" /></td>
        <td>&nbsp;</td>
    </tr>

</table>                    
                </td>
            </tr>
        </table>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div id="images">
        <MenuComponent />
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#projects"><b>Projects</b></a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#images">Images</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#status">Status</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#message">Messages</a></div> &nbsp;<div style={{float:'right'}}>
            <button onClick={doSave} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <button onClick={doClose} type="button">Close</button></div>

    </div>
    <div style={{width:'100%'}}>
        <b>Website:</b> <input id="websiteImage" TabIndex="1" style={{width:250}} type="text" /><br />
        <b>Preview:</b><br />
        <div id="previewImageDiv" style={{width:'100%',border:1}}>
            <img id="PreviewImage" src="Images/noimage.png" />
        </div>
        <b>File Upload:</b><br />
        <button onClick={() => openFilePicker()}>Select File</button>
        &nbsp;&nbsp;&nbsp;
        <b>{_selectedFile}</b>

      &nbsp;&nbsp;&nbsp;
      <button style={{backgroundColor:'green'}} type="button">Upload</button>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div id="status">
        <MenuComponent />
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#projects"><b>Projects</b></a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#images">Images</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#status">Status</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#message">Messages</a></div> &nbsp;<div style={{float:'right'}}>
            <button onClick={doSave} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <button onClick={doClose} type="button">Close</button></div>
            
    </div>
    <div>
<br />
<table>
<tr>
<td id="column1" valign="top">
    <table>
        <tr>
            <td style={{height:40}} align='right'>Deposit % </td>
            <td><input value={_c1r1} id="c1r1" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Deposit </td>
            <td><input value={_c1r2} id="c1r2" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Acknowledgement </td>
            <td><input value={_c1r3} id="c1r3" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Acknowledgement Date </td>
            <td><input value={_c1r4} id="c1r4" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Est Ship Date </td>
            <td><input value={_c1r5} id="c1r5" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Ship Date </td>
            <td><input value={_c1r6} id="c1r6" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Received </td>
            <td><input value={_c1r7} id="c1r7" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Delievered </td>
            <td><input value={_c1r8} id="c1r8" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Installed </td>
            <td><input value={_c1r9} id="c1r9" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Rcv Notes </td>
            <td><input value={_c1r10} id="c1r10" style={{width:250}} type="text" /></td>
        </tr>
    </table>
</td>
<td>&nbsp;&nbsp;</td>
<td id="column2" valign="top">
    <table>
        <tr>
            <td style={{height:40}} align='right'>Vendor Invoice # </td>
            <td><input value={_c2r1} id="c2r1" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Vendor Invoice Date </td>
            <td><input value={_c2r2} id="c2r2" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Vendor Invoice Due </td>
            <td><input value={_c2r3} id="c2r3" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Check Amount </td>
            <td><input value={_c2r4} id="c2r4" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} colspan="2">&nbsp;</td>
        </tr>
        <tr>
            <td style={{height:40}} colspan="2">&nbsp;</td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Qty Rcvd </td>
            <td><input value={_c2r7} id="c2r7" style={{width:250}} type="text" /></td>
        </tr>
    </table>
</td>
<td>&nbsp;&nbsp;</td>
<td id="column3" valign="top">
    <table>
        <tr>
            <td style={{height:40}} align='right'>Entered By </td>
            <td><input value={_c3r1} id="c3r1" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Entered Date </td>
            <td><input value={_c3r2} id="c3r2" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Modified By </td>
            <td><input value={_c3r3} id="c3r3" style={{width:250}} type="text" /></td>
        </tr>
        <tr>
            <td style={{height:40}} align='right'>Modified Date </td>
            <td><input value={_c3r4} id="c3r4" style={{width:250}} type="text" /></td>
        </tr>
    </table>
</td>    
</tr>

</table>


    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div id="message">
        <MenuComponent />
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#projects"><b>Projects</b></a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#images">Images</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#eeeeee'}}><a href="#status">Status</a></div> &nbsp;
        <div style={{textAlign:'center', border:'1px solid black', float:'left',width:'90px',height:'30px',backgroundColor:'#ffaaaa'}}><a href="#message">Messages</a></div> &nbsp;<div style={{float:'right'}}>
            <button onClick={doSave} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
            <button onClick={doClose} type="button">Close</button></div>

    </div>
    <div>
        <br />
    <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Action Date</th>
            <th>Description</th>
            <th>Date Entered</th>
            <th>Entered By</th>
          </tr>
        </thead>
        <tbody>
          {
            messData.map((obj) => {
              return (
                <tr>
                  <td>{obj.Employee}</td>
                  <td>{obj.Action_Date}</td>
                  <td>{obj.Description}</td>
                  <td>{obj.Date_Entered}</td>
                  <td>{obj.Entered_By}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

<AddThing ref={childRef} />
</div>
);

};

export default ProjectDetails;
