import React, { useEffect, useState, forwardRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button, Checkbox } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';
import CompanyBlock from './CompanyBlock';

const ClientApproval = forwardRef((props,ref) =>
{

    const [_dt, setDT] = useState('');

	useEffect(() => 
	{
        let d = new Date();
        let dt = (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
        setDT( dt );
        populateCheckboxes();
        populateTables();
    }, []);

    const [_filters,setFilters] = useState([]);
    const [_exclude,setExclude] = useState([]);

    function addFilter( newFilter )
    {
        let newArray = [];
        let found = false;
        for( let i=0; i<_filters.length; i++ )
        {
            newArray.push( _filters[i] );
            if( _filters[i] == newFilter )
            {
                found = true;
            }
        }

        if( !found )
        {
            newArray.push( newFilter );
        }

        setFilters( newArray );
        repopulateTable( newArray, _exclude );
    }

    function addExclude( newExclude )
    {
        let newArray = [];
        let found = false;
        for( let i=0; i<_exclude.length; i++ )
        {
            newArray.push( _exclude[i] );
            if( _exclude[i] == newExclude )
            {
                found = true;
            }
        }

        if( !found )
        {
            newArray.push( newExclude );
        }

        setExclude( newArray );
        repopulateTable( _filters, newArray );
    }

    function removeFilter( oldFilter )
    {
        let newArray = [];
        let found = false;
        for( let i=0; i<_filters.length; i++ )
        {
            if( _filters[i] == oldFilter )
            {
                found = true;
            }
            else
            {
                newArray.push( _filters[i] );
            }
        }

        setFilters( newArray );
        repopulateTable( newArray, _exclude );
    }

    function inFilterList( room, newArray )
    {
        for( let i=0; i<newArray.length; i++ )
        {
            if( room == newArray[i] )
            {
                return true;
            }
        }
        return false;
    }

    function inExcludeList( id, newArray )
    {
        for( let i=0; i<newArray.length; i++ )
        {
            if( id == newArray[i] )
            {
                return true;
            }
        }
        return false;
    }

    function repopulateTable( newArray, newArray2 )
    {
        let jsonArray = [];
        for( let i=0; i<_tableDataToSave.length; i++ )
        {
            let d = _tableDataToSave[i];
            if( !inFilterList( d.Room, newArray ) &&
                !inExcludeList( d.id, newArray2 ))
            {
                jsonArray.push( d );
            }
        }
        setTableData( jsonArray );
    }

    const [_checked, setCheckBoxes] = useState([]);
    async function populateCheckboxes()
    {
        let cb = await Helpers.getRooms(props.clientId);
        cb = cb.roomData;
        setCheckBoxes( cb );
    }

    const [_tableData, setTableData] = useState([]);
    const [_tableDataToSave, setTableDataToData] = useState([]);

    async function populateTables()
    {
        let al = await Helpers.getClientApprovalList(props.clientId,_filters);
        al = al.approvalData;
        setTableData( al );
        setTableDataToData( al );
    }

//#region AddBudgetItemCode
    const [openClientApproval, setOpenClientApproval] = React.useState(false);

    const handleClickOpenClientApproval = () => 
    {
        setOpenClientApproval(true);
    };

    const handleCloseClientApproval = () => 
    {
        setOpenClientApproval(false);
    };

    ref.current = handleClickOpenClientApproval;

    function handlePrint()
    {

    }

    function handleCheckboxClick(event)
    {
        let val = event.target.value;
        let checked = event.target.checked;

        if( checked )
        {
            addFilter( val );
        }
        else
        {
            removeFilter( val );
        }
    }

    function handleExclude(event)
    {
        let val = event.target.className;
        addExclude( val );
    }

    return(
        <div>
<Dialog 
    open={openClientApproval}
    onClose={handleCloseClientApproval}
    fullWidth
    maxWidth="md"

    >
    <DialogTitle>

        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>Client Approval</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseClientApproval}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent>
        <Button onClick={handlePrint}>
            Print
        </Button><br />
        Filter: Room<br />

        {_checked.map((_checked, index) => (
            <label style={{padding:'5px'}} key={_checked.Room}>
                <input type="checkbox" value={_checked.Room} onClick={handleCheckboxClick} /><br />
                {_checked.Room}
            </label>
        ))}

        <br /><Button onClick={handlePrint}>
            Apply Filter
        </Button> (Apply the filter beore EXCLUDING items)<br />

        <table width="100%">
            <tr>
                <td>
                    <CompanyBlock />
                </td>
                <td align="right">Client Approval<br />Client: {props.clientId}<br />Proposal #: ALL<br />Date: {_dt}</td>
            </tr>
        </table><br />





        <table width="100%">
        {
            _tableData.map((obj) => {
              return (
                <tr>

<td>
<hr /><br />
<table width="100%"><tr>

                  <td width="100px">{obj.Room}</td>
                  <td>
                    <table>
                            <tr><td align="right">Quantity: </td><td>{obj.QTY} &nbsp;&nbsp;Unit: {obj.Unit}</td></tr>
                            <tr><td align="right">Description: </td><td>{obj.Item_Description}</td></tr>
                            <tr><td colspan="2"> &nbsp;</td></tr>
                            <tr><td colspan="2"> &nbsp;</td></tr>
                            <tr><td colspan="2"> &nbsp;</td></tr>
                            <tr><td align="right">Note: </td><td> &nbsp;</td></tr>
                    </table>
                  </td>
                  <td width="100px">
                    <table>
                        <tr valign="bottom">
                            <td>Initial x_______________________________</td>
                        </tr>
                    </table>
                  </td>
                  <td width="100px" valign="top">
                    <a href="#" class={obj.id} onClick={handleExclude}>EXCLUDE</a>
                  </td>

</tr></table>
</td>

                </tr>
              );
            })
          }
        </table>


<br /><br />Accepted and Approved<br /><br /><br />
<hr />
Signature                                  Date<br />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseClientApproval}>
            Thanks
        </Button>
    </DialogActions>
</Dialog>

</div>
    );

});

export default ClientApproval;
