import React, { useEffect, useState, forwardRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button, Checkbox } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';
import CompanyBlock from './CompanyBlock';

//function ClientApprovalForm(props)
const ClientApprovalForm = forwardRef((props,ref) =>
{

//#region AddBudgetItemCode
    const [openClientApprovalForm, setOpenClientApprovalForm] = React.useState(false);
    const [_operation, setOperation] = React.useState('');

    const handleClickOpenClientApprovalForm = (operation) => 
    {
        setOperation( operation );
        setOpenClientApprovalForm(true);
    };

    const handleCloseClientApprovalForm = () => 
    {
        setOpenClientApprovalForm(false);
    };

    ref.current = handleClickOpenClientApprovalForm;

    return(
<Dialog 
    open={openClientApprovalForm}
    onClose={handleCloseClientApprovalForm}
    fullWidth
    maxWidth="lg"

    >
    <DialogTitle>

        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>Client Approval Form</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseClientApprovalForm}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent>
        <table style={{width:'100%'}}>
            <tr>
                <td style={{width:'50%'}}>
                    <CompanyBlock />
                </td>
                <td style={{width:'50%'}} align="right">
                    <b>Client Approval</b><br />
                    Client: {props.clientId}<br />
                    Proposal #: M0027<br />
                    Date: {Helpers.todaysDate()}
                </td>
            </tr>
        </table>

        <hr />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />






        Accepted and Approved<br /><br />
        ___________________________________________<br />
        <table><tr><td style={{width:'200px'}}>Signature</td><td>Date</td></tr></table>



    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseClientApprovalForm}>
            Thanks
        </Button>
    </DialogActions>
</Dialog>
    );

});

export default ClientApprovalForm;
