import React, { useEffect } from 'react';
import MenuComponent from '../components/Menu';

const SettingsPage = () => {
    useEffect(() => {
        document.title = 'Settings';
    }, []);

    const st = require('../checkSessionTimeout.js');
    if (st.checkSession()) {
        window.location.href = '/';
    }

    // Button size setting (width and height)
    const buttonSize = { width: '150px', height: '30px' };

    return (
        <div>
            <MenuComponent />

            <div style={{ width: '100%', marginLeft: '5px' }}>
                {/* Change ProDesigner Password Section */}
                <h2 style={{ textAlign: 'left', marginLeft: '5px', marginTop: '20px', fontWeight: 'bold' }}>Change ProDesigner Password</h2>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <label htmlFor="newPassword" style={{ display: 'inline-block', width: '150px' }}>New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        maxLength="30"
                        style={{ height: '30px', width: '170px', display: 'inline-block' }}
                    />
                    <small style={{ marginLeft: '10px' }}></small>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="retypeNewPassword" style={{ display: 'inline-block', width: '150px' }}>Retype New Password</label>
                    <input
                        type="password"
                        id="retypeNewPassword"
                        style={{ height: '30px', width: '170px', display: 'inline-block' }}
                    />
                </div>
                <button className="btn btn-success" style={{ display: 'block', marginBottom: '30px', backgroundColor: 'rgb(0, 128, 0)' }}>Submit Password</button>

                {/* Separator Line */}
                <hr />

                {/* User Settings Section */}
                <h2 style={{ textAlign: 'left', marginTop: '30px', marginBottom: '25px', fontWeight: 'bold' }}>User Settings</h2>
                <div style={{ marginLeft: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                        <label htmlFor="email" style={{ display: 'inline-block', width: '150px', textAlign: 'right', marginRight: '8px' }}>Email:</label>
                        <input
                            type="email"
                            id="email"
                            style={{ width: '250px', display: 'inline-block' }}
                        />
                        <label style={{ marginLeft: '5px' }}>(Required for sending emails. EX: username@masterpieceinteriors.com)</label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                        <label htmlFor="windowsId" style={{ display: 'inline-block', width: '150px', textAlign: 'right', marginRight: '8px' }}>Windows ID:</label>
                        <input
                            type="text"
                            id="windowsId"
                            style={{ width: '250px', display: 'inline-block', border: '2px solid red' }}
                            placeholder="none"
                        />
                        <label style={{ marginLeft: '5px' }}>(Required for sending emails. EX: MDG\\WindowsUsername)</label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1px' }}>
                        <label htmlFor="windowsPassword" style={{ display: 'inline-block', width: '150px', textAlign: 'right', marginRight: '8px' }}>Windows Password:</label>
                        <input
                            type="password"
                            id="windowsPassword"
                            style={{ width: '250px', display: 'inline-block', border: '2px solid red' }}
                        />
                    </div>
                    <div style={{ marginLeft: '160px', marginBottom: '20px' }}>
                        <button className="btn btn-success" style={{ ...buttonSize, marginRight: '20px', backgroundColor: 'rgb(0, 128, 0)' }}>Save</button>
                        <button className="btn btn-primary" style={{ ...buttonSize, backgroundColor: 'rgb(70, 130, 180)'}}>Verify Email</button>
                    </div>
                </div>


                {/* Separator Line */}
                <hr />

                {/* System Tables Section */}
                <h2 style={{ textAlign: 'left', fontWeight: 'bold', marginTop: '45px', marginBottom: '20px' }}>System Tables</h2>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className="btn btn-primary" style={{ ...buttonSize, marginBottom: '25px' }}>Locations</button>
                    <button className="btn btn-primary" style={{ ...buttonSize, marginBottom: '25px' }}>TERMS</button>
                    <button className="btn btn-primary" style={{ ...buttonSize, marginBottom: '25px' }}>Units Measured</button>
                </div>

                {/* Separator Line */}
                <hr />

                {/* Encryption Key and Session ID Section */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '25px' }}>
                    <label style={{ display: 'inline-block', width: '150px' }}>Encryption Key:</label>
                    <p style={{ display: 'inline-block' }}>{/* Add your encryption key here */}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <label style={{ display: 'inline-block', width: '150px' }}>Session ID:</label>
                    <p style={{ display: 'inline-block' }}>{/* Add your session ID here */}</p>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
