import React, { useEffect, useState } from 'react';
import MenuComponent from '../components/Menu';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

const HelpPage = () =>
{
	useEffect(() => 
	{
		document.title = 'Help';
	}, []);

    var st = require('../checkSessionTimeout.js');
    if( st.checkSession() )
    {
        window.location.href = '/';
    }

    return(
<div>
    <MenuComponent />
    <img src='Images/Designer-joke-35.jpg' />




</div>
);

};

export default HelpPage;
