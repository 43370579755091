import React, { useEffect, useState } from 'react';
import MenuComponent from '../components/Menu.js';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {Link} from '@material-ui/core';

const HomePage = () =>
{
    // The following lines are commented out temporarily so that the API is not called.
    let st = require('../checkSessionTimeout.js');
    if( st.checkSession() )
    {
        window.location.href = '/';
    }

    let userData = localStorage.getItem('user_data');
    let ud = JSON.parse(userData);

    let data2 =
    [
        { "id":1, "Address_ID":"", "Name":"", "TotalItems":0, "Disco":0, "BO":0, "PC":0 }
    ];
    const [data, setData] = useState(data2);
    const [pageSize, setPageSize] = useState(20);

    let columns = 
    [
      { field: "Address_ID", headerName: "Address", width: 130, headerClassName: 'custom-header', renderCell: (params) => <Link href={`/Project2?clientid=${params.value}`}>{params.value}</Link>},
      { field: "Name", headerName: "Name", width: 400, headerClassName: 'custom-header' },
      { field: "TotalItems", headerName: "Total Items", width: 100, headerClassName: 'custom-header'},
      { field: "Disco", headerName: "Disco", width: 100, headerClassName: 'custom-header2'},
      { field: "BO", headerName: "BO", width: 100, headerClassName: 'custom-header3'},
      { field: "PC", headerName: "PC", width: 100, headerClassName: 'custom-header4' }
    ];

	useEffect(() => 
	{
		document.title = 'Welcome ' + ud.employeeName;
        getData();
	}, []);

    function conforms( Address_ID )
    {
        if( Address_ID.length != 7 )
        {
            return false;
        }

        for( let i=0; i<Address_ID.length; i++ )
        {
            let thisChar = Address_ID[i];
            if( !(thisChar >= '0' && thisChar <= '9') )
            {
                return false;
            }
        }

        return true;
    }

    async function getData()
    {
        try
        {
            // The following lines are commented out temporarily so that the API is not called.
            let bp = require('../Path.js');
            let obj = {userName:ud.login};
            let js = JSON.stringify(obj);
            const response = await fetch(bp.buildPath('api/dashboard'),
                {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
            let res = JSON.parse(await response.text()).retData;
            res = JSON.parse( res );

            // Address_ID
            let jsonArray = [];
            for( let i=0; i<res.data.length; i++ )
            {
                let Address_ID = res.data[i].Address_ID;
                if( conforms( Address_ID ) )
                {
                    jsonArray.push( res.data[i] );
                }
            }
            res.data = jsonArray;

            let textVersion = JSON.stringify( res );

            let index = textVersion.indexOf( '[');
            textVersion = textVersion.substring(index,textVersion.length-1);
            let res2 = JSON.parse( textVersion );

            for( let i=0; i<res2.length; i++ )
            {
                let child = res2[i];
                localStorage.setItem(child.Address_ID, child.Name);
            }

            setData( res2 );
        }
        catch(e)
        {
            console.log( e );
        }
    };

    return(
<div>
    <MenuComponent />

    <div style={{width:"100%"}}>
        <h3>Dashboard</h3>
    </div>

    <div style={{width:"100%"}}>
        <div>
            <b>My Messages</b>
            <input id="ButtonNewMsg" type="button" value="create new" class="btn btn-sm btn-outline-success" style={{borderRadius:"10px", display:"none"}} />

            <div style={{marginLeft:"10px"}}>
                <div>
                	<table class="FW" cellspacing="0" cellpadding="3" rules="all" border="1" id="GridView1" style={{borderCollapse:"collapse"}}>
                		<tr>
			                <td colspan="7">No Messages Found</td>
		                </tr>
	                </table>
                </div>
            </div>            
        </div>

        <div style={{height:"500", width:"930"}}>
            <div><b>My Current Projects:</b></div>

            <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                rowHeight={25}
                headerHeight={25}
            />            
        </div>

    </div>

</div>
);

};

export default HomePage;
