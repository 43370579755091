import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import DefaultPage from './pages/default';
import HomePage from './pages/home';
import AddressPage from './pages/address';
import ProjectsPage from './pages/project2';
import ProposalListPage from './pages/proposallist';
import POListPage from './pages/poList';
import ExpeditingPage from './pages/expedite';
import ReceivingPage from './pages/receiving';
import InstallsPage from './pages/install';
import ClaimsPage from './pages/claims';
import InventoryPage from './pages/inventory';
import ReportsPage from './pages/reports';
import SettingsPage from './pages/settings';
import HelpPage from './pages/help';
import ProjectDetailsPage from './pages/projectDetails';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultPage />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/address" element={<AddressPage />} />
        <Route path="/project2" element={<ProjectsPage />} />
        <Route path="/proposallist" element={<ProposalListPage />} />
        <Route path="/poList" element={<POListPage />} />
        <Route path="/expedite" element={<ExpeditingPage />} />
        <Route path="/receiving" element={<ReceivingPage />} />
        <Route path="/install" element={<InstallsPage />} />
        <Route path="/claims" element={<ClaimsPage />} />
        <Route path="/inventory" element={<InventoryPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/projectDetails" element={<ProjectDetailsPage />} />
        <Route path="/help" element={<HelpPage />} />
      </Routes>
    </BrowserRouter>
      );
}
//test
export default App;
