import React from 'react';

const ModalComponent = ({ title, isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2>{title}</h2>
        <div style={styles.formGroup}>
          <label>Start Date:</label>
          <input type="date" />
        </div>
        <div style={styles.formGroup}>
          <label>End Date:</label>
          <input type="date" />
        </div>
        <div style={styles.buttons}>
          <button onClick={onClose} style={styles.button}>Close</button>
          <button onClick={onSubmit} style={styles.button}>Submit</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    textAlign: 'center',
  },
  formGroup: {
    marginBottom: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
};

export default ModalComponent;
