const app_name = 'cop4331-9-19-2023-ed38a9ffe472'
exports.buildPath = 
function buildPath(route)
{
    if (process.env.NODE_ENV === 'production') 
    {
        return 'http://S72-167-225-129:5000/' + route;
    }
    else
    {        
        return 'http://S72-167-225-129:5000/' + route;
    }
}
