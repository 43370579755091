import React, { useEffect, useState, useRef } from 'react';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

function ClientInfo(props)
{
    let clientProject = props.clientProject;
    const [infoHeight, setInfoHeight] = useState(40);

    useEffect(() => 
    {
    }, [infoHeight])

    return(
        <div style={{border:'solid 2px', borderColor:'lightgray', height:`${infoHeight}px`, overflow:"hidden", padding:" 4px 2px 2px 2px"}}>
            <div onClick={() => setInfoHeight(infoHeight==155?40:155)} style={{height: `${infoHeight}px`, float:"left", left:"0"}}><img alt={''} src='Images/collapse.png' style={{width:40, height:40}} /></div>
            <div style={{height: `${infoHeight}px`, float:"left"}}>
                Client: <span style={{textDecoration:"underline", fontWeight:"bold"}}>{clientProject}</span> <button type="button">edit</button><br />
                {props.projectName}<br />
                Builder: {props.builder}<br />
                {props.address}<br />
                Phone: {props.phone}<br />
                Fax: {props.fax}<br />
                email: {props.email}<br />
            </div>
            <div style={{height: `${infoHeight}px`, float:"left"}}>
                Total Cost: ${props.totalCost} ({props.percent}% completed)
            </div>
            <div style={{height: `${infoHeight}px`, float:"left", position:"absolute", right:"0"}}>
                Notes: {props.notes}
            </div>
        </div>
        );
       
}

export default ClientInfo;
