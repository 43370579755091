import React, { useState, useEffect, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';

const BudgetSummary = forwardRef((props,ref) =>
{
    const [todayDate, setTodayDate] = React.useState('');

	useEffect(() => 
	{
        let dt = new Date();
        let sd = (dt.getMonth()+1) + "/" + dt.getDate() + "/" + dt.getFullYear();
        setTodayDate( sd );
    }, []);

//#region AddBudgetItemCode
    const [openBudgetSummary, setOpenBudgetSummary] = React.useState(false);
    const handleClickOpenBudgetSummary = () => 
    {
        setOpenBudgetSummary(true);
    };
    const handleCloseBudgetSummary = () => 
    {
        setOpenBudgetSummary(false);
    };
    ref.current = handleClickOpenBudgetSummary;
//#endregion

    useEffect(() => 
    {
        generateData();
    }, []);

    let USDollar = new Intl.NumberFormat('en-US', 
    {
        style: 'currency',
        currency: 'USD',
    });

    async function generateData()
    {
        let d = await Helpers.getBudgetSummary(props.clientId);
        d = await JSON.parse( d.budgetData );

        let jsonArray = [];

        let totals = [0.00,0.00,0.00];

        for( let i=0; i<d.length; i++ )
        {
            let sc = d[i].SalesCode;
            let tmp = parseFloat( d[i].Budget_Cost );
            totals[0] += tmp;
            let bc = USDollar.format( tmp ).substring( 1 );
            tmp = parseFloat( d[i].Purchase_Cost );
            totals[1] += tmp;
            let pc = USDollar.format( tmp ).substring( 1 );
            tmp = parseFloat( d[i].Variance );
            totals[2] += tmp;
            let vr = USDollar.format( tmp ).substring( 1 );
            jsonArray.push({SalesCode:sc,Budget_Cost:bc,Purchase_Cost:pc,Variance:vr});
        }
        let t0 = USDollar.format( totals[0] ).substring( 1 );
        let t1 = USDollar.format( totals[1] ).substring( 1 );
        let t2 = USDollar.format( totals[2] ).substring( 1 );
        jsonArray.push({SalesCode:'Job Summary',Budget_Cost:t0,Purchase_Cost:t1,Variance:t2});

        // <td>{obj.SalesCode}</td>
        // <td>{obj.Budget_Cost}</td>
        // <td>{obj.Purchase_Cost}</td>
        // <td>{obj.Variance}</td>

        setTableData( jsonArray );
    }

    const [tableData, setTableData] = useState([{SalesCode:'',Budget_Cost:'',Purchase_Code:'',Variance:''}]);

    return(
<Dialog 
    open={openBudgetSummary}
    onClose={handleCloseBudgetSummary}

    PaperProps={{sx:{position:'fixed',width:'750' }}}

    >
    <DialogTitle>
        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <span style={{fontSize:25}}>Budget Summary</span>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseBudgetSummary}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent sx={{height:730, width:600}}>
        <b>PROJECT Budget SUmmary</b><br /><br />
        <table width={'100%'}>
            <tr>
                <td width={'50%'}>{props.Description} - {props.clientId}</td>
                <td width={'50%'} align='right'>Date: {todayDate}</td>
            </tr>
        </table>
        <table border="1" style={{width: '100%'}}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th align='center'>Budget</th>
            <th align='center'>Purchase</th>
            <th align='center'>Variance</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.map((obj) => {
              return (
                <tr>
                  <td border="1">{obj.SalesCode}</td>
                  <td border="1" align='right'>{obj.Budget_Cost}</td>
                  <td border="1" align='right'>{obj.Purchase_Cost}</td>
                  <td border="1" align='right'>{obj.Variance}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </DialogContent>
    <DialogActions>
        <Button autoFocus onClick={handleCloseBudgetSummary}>
            Thanks
        </Button>
    </DialogActions>
</Dialog>
    );

});

export default BudgetSummary;
