import React, { useEffect, useState, useRef } from 'react';
import MenuComponent from '../components/Menu';
import LibrarySpecs from '../components/LibrarySpecs';
import Helpers from './project2Helpers';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {Link} from '@material-ui/core';
import AddressDetails from '../components/AddressDetails.js';

import "../Scripts/normalize.css";
import "../StyleSheet1.css";
import "../Content/bootstrap.min.css";
import "../Content/css/select2.min.css";
import "../Content/bootstrap-datepicker.min.css";

const AddressPage = () =>
{

    const childRef = useRef(null);

	useEffect(() => 
	{
		document.title = 'Library';
	}, []);

    var st = require('../checkSessionTimeout.js');
    if( st.checkSession() )
    {
        window.location.href = '/';
    }

    let columns = 
    [
      { field: "_ID", headerName: "ID", width: 150, headerClassName: 'custom-header-projectlist', renderCell: (params) => {
        let linkValue = params.value.replace(" ","%20");
        return (
        <div>
            <Link to={`/AddressDetails?id=${linkValue}&action=edit`}>
                <span onClick={doEdit}>{params.value}</span>
            </Link>
        </div>
        );
        }},
      { field: "Name", headerName: "Name", width: 200, headerClassName: 'custom-header-projectlist' },
      { field: "Email", headerName: "Email", width: 200, headerClassName: 'custom-header-projectlist' },
      { field: "Phone", headerName: "Phone", width: 110, headerClassName: 'custom-header-projectlist' },
      { field: "Fax", headerName: "Fax", width: 110, headerClassName: 'custom-header-projectlist' },
      { field: "Type", headerName: "Type", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Codes", headerName: "Codes", width: 300, headerClassName: 'custom-header-projectlist' }
    ];

    let data2 =
    [
        { "id":1,"_ID":"","Name":"","Email":"","Phone":"","Fax":"","Type":"","Codes":"" }
    ];
    const [data, setData] = useState(data2);

    async function getItems(codes,type,checked,id)
    {
        try
        {
            document.body.style.cursor = "wait";

            let res = await Helpers.searchLibraries(codes,type,checked,id);

            let json = [];
            for( let i=0; i<res.libraryResults.length; i++ )
            {
                let res2 = res.libraryResults[i];

                let codes = res2.Codes;
                let more = false;
                for( let j=0; j<codes.length; j++ )
                {
                    let c = codes[j];
                    if( c == ' ' || c == ',' || c == '\'' || c == '\"' )
                    {

                    }
                    else
                    {
                        more = true;
                        break;
                    }
                }
                if( !more )
                {
                    codes = "";
                }
                json.push({"id":i,"_ID":res2._ID,"Name":res2.Name,"Email":res2.Email,"Phone":res2.Phone,"Fax":res2.Fax,"Type":res2.Type,"Codes":codes});
            }

            setData( json );//res.libraryResults );//2.libraryResults );
            document.body.style.cursor = "pointer";
        }
        catch(e)
        {
            document.body.style.cursor = "pointer";
            console.log( e );
        }
    };

//    const doSearch = async (event,id,type,checked,codes) => 
//    {
//        getItems(codes,type,checked,id);
//    }

    function doSearch(id,type,checked,codes)
    {
        getItems(codes,type,checked,id);
//        alert( "doSearch" );
    }

    function doCreateNew()
    {
        childRef.current('new','');
    }

    function doEdit(event)
    {
        childRef.current('edit',event.target.textContent);
    }

    function doExport()
    {
        alert( "doExport" );
    }

    return(
<div>
    <MenuComponent />
    <LibrarySpecs doSearch={doSearch} doCreateNew={doCreateNew} doExport={doExport} />

    <DataGrid
        rows={data}
        columns={columns}
        initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}

        allowTextWrap={true}
        rowHeight={25}

        sx={{
            [`& .MuiDataGrid-cell`]: {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal',
                border: `1px solid transparent`,
                borderRight: `1px solid #dddddd`,
                borderBottom: `1px solid #dddddd`,
                wordWrap: 'break-word',
                wordBreak: 'break-word'
            },
            [`& .MuiDataGrid-columnHeader`]: {
              maxHeight: 'none !important',
              height: 'auto !important',
              whiteSpace: 'inherit !important',
              overflow: 'inherit !important',
              lineHeight: '24px !important'
            },
            [`& .MuiDataGrid-columnHeaderTitle`]: {
              whiteSpace: 'normal !important',
            },
        }}        
    />            

    <AddressDetails ref={childRef} />

</div>
);

};

export default AddressPage;
