import React, { forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
}));

const SetPriority = forwardRef((props,ref) =>
{

    const classes = useStyles();

//#region SetPriorityCode
    const [openSetPriority, setOpenSetPriority] = React.useState(false);
    const [_id, setID] = React.useState( '12345' );
    const [_stateColor, setStateColor] = React.useState( 'white' );
    const [_ackDate, setAckDate] = React.useState( '2024-1-1');
    const [value, setPriorityValue] = React.useState('green');
    const [_left, setLeft] = React.useState(120);
    const [_top, setTop] = React.useState(100);
    const [_divId, setDivId] = React.useState('');
    const [_priorityCallback, setPriorityCallback] = React.useState();
    let _sPR;

    //setPriorityCallback( props.handler );
    _sPR = props.handler;

    const handleClickOpenSetPriority = (id,stateColor,ackDate,respondToPriority,x,y,divId) => 
    {
        setID( id );
        setStateColor( stateColor );
        setPriorityValue( stateColor );
        ackDate = ackDate.substring( 5 ) + "-" + ackDate.substring( 0, 3 );
        ackDate = ackDate.replace("/","-");
        setAckDate( ackDate );
        setLeft( x );
        setTop( y );
        setDivId( divId );
        setOpenSetPriority(true);
    };
    const handleCloseSetPriority = () => 
    {
        setOpenSetPriority(false);
    };
    ref.current = handleClickOpenSetPriority;

//#endregion
    const handleChangePriority = (event) => 
    {
        setPriorityValue( event.target.value );
        _sPR( event.target.value );
    }

    return(
<Dialog
    open={openSetPriority}
    onClose={handleCloseSetPriority}

    PaperProps={{sx:{position:'fixed',top:{_top}, left:120 }}}

    >
    <DialogTitle>
        {"Set Priority"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <IconButton onClick={handleCloseSetPriority}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent sx={{height:230, width:250}} >
        ID: {_id}<br />

    <RadioGroup aria-label="Priority" name="priority" value={value} onChange={handleChangePriority}>
        <FormControlLabel style={{backgroundColor:'green',color:'white'}} value="green" control={<Radio color='black' />} label="Green" />
        <FormControlLabel style={{backgroundColor:'yellow'}} value="yellow" control={<Radio color='black' />} label="Yellow" />
        <FormControlLabel style={{backgroundColor:'red',color:'white'}} value="red" control={<Radio color='black' />} label="Red" />
    </RadioGroup>
    <br />
    <TextField
        id="date"
        label="Acknowledge Date"
        type="date"
        defaultValue={_ackDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={props.handler}
      />

    </DialogContent>
    <DialogActions>
    </DialogActions>
</Dialog>
    );

});

export default SetPriority;
