
exports.sm = function( tokens )
{
    return tokens.split( '~' );
}

exports.getImageURL = function( value, clientId )
{
    let url = `Images/${value}`;
    let foundExtension = true;
    if( url.toUpperCase().indexOf(".JPG")<0 &&
        url.toUpperCase().indexOf(".PNG")<0)
    {
        foundExtension = false;
    }
    if( !foundExtension )
    {
        url = "Images/noimage.png";
    }
    else if( foundExtension && url.toUpperCase().indexOf('NOIMAGE')<0)
    {
        if( value.indexOf("\\") > 0 )
        {
            url = "http://prodesigner/StudioImages/" + value.replace("\\","/");
        }
        else
        {
            url = "Uploads/" + clientId.trim();
            url += "/";
            url += value;
        }
    }
    return url;
}

exports.getColor = function( tokens )
{
    let priority = 'red'
    if( tokens[3] == 'n' )
    {
        priority = 'green';
    }
    else if( tokens[4] == 'n' )
    {
        priority = 'yellow';
    }
    return priority;
}

exports.getStatusColor = function( statusType )
{
    let col = 'white';
    if( statusType == 'Other' )
    {
        col = 'yellow';
    }
    else if( statusType == 'New Order/Info Needed' )
    {
        col = 'yellow';
    }
    else if( statusType == 'Processing/Paid' )
    {
        col = '#22ff22';
    }
    else if( statusType == 'Shipped' )
    {
        col = 'green';
    }
    else if( statusType == 'Received' )
    {
        col = 'purple';
    }
    else if( statusType == 'CNXL/DISCO PENDING' )
    {
        col = '#880000';
    }
    else if( statusType == 'CNXL/DISCO' )
    {
        col = 'black';
    }
    else if( statusType == 'CLAIM' )
    {
        col = 'orange';
    }
    else if( statusType == 'RTS/TRSFR' )
    {
        col = 'gray';
    }
    else if( statusType == 'ON HOLD/UNDER MIN' )
    {
        col = '#ff8800';
    }
    else if( statusType == 'Disco' )
    {
        col = '#880000';
    }
    else if( statusType == 'B/O' )
    {
        col = 'red';
    }
    else if( statusType == 'Low Stock' )
    {
        col = '#880000';
    }
    else if( statusType == 'Price Change' )
    {
        col = 'yellow';
    }
    else if( statusType == 'RTS/Transferred' )
    {
        col = 'gray';
    }
    else if( statusType == 'Cancelled' )
    {
        col = 'black';
    }
    else if( statusType == 'Pending Cancel' )
    {
        col = '#880000';
    }
    return col;
}

exports.getForeColor = function( statusType )
{
    let col = 'black';
    if( statusType == 'Other' )
    {
//            col = 'yellow';
    }
    else if( statusType == 'New Order/Info Needed' )
    {
//            col = 'yellow';
    }
    else if( statusType == 'Processing/Paid' )
    {
//            col = 'white';
//            col = '#22ff22';
    }
    else if( statusType == 'Shipped' )
    {
//            col = 'white';
//            col = 'green';
    }
    else if( statusType == 'Received' )
    {
        col = 'white';
//            col = 'purple';
    }
    else if( statusType == 'CNXL/DISCO PENDING' )
    {
        col = 'white';
//            col = '#880000';
    }
    else if( statusType == 'CNXL/DISCO' )
    {
        col = 'white';
//            col = 'black';
    }
    else if( statusType == 'CLAIM' )
    {
//            col = 'orange';
    }
    else if( statusType == 'RTS/TRSFR' )
    {
//            col = 'gray';
    }
    else if( statusType == 'ON HOLD/UNDER MIN' )
    {
//            col = '#ff8800';
    }
    else if( statusType == 'Disco' )
    {
        col = 'white';

//           col = '#880000';
    }
    else if( statusType == 'B/O' )
    {
//            col = 'red';
    }
    else if( statusType == 'Low Stock' )
    {
        col = 'white';
//            col = '#880000';
    }
    else if( statusType == 'Price Change' )
    {
//            col = 'yellow';
    }
    else if( statusType == 'RTS/Transferred' )
    {
//            col = 'gray';
    }
    else if( statusType == 'Cancelled' )
    {
        col = 'white';
//            col = 'black';
    }
    else if( statusType == 'Pending Cancel' )
    {
        col = 'white';
//            col = '#880000';
    }
    return col;
}

exports.callPriorityAPI = async function( ID2, priority )
{
    var bp = require('../Path.js');
  
    var obj = {ID2:ID2,priority:priority};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/setPriority'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        if( res.error.trim().length >= 0 )
        {
        }
    }
    catch(e)
    {
        alert(e.toString());
        return;
    }    

}

exports.callGetDropDownsAPI = async function()
{
    var bp = require('../Path.js');
  
    var obj = {};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getDropDowns'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return;
    }    

}

exports.callGetItem = async function(id)
{
    var bp = require('../Path.js');
  
    var obj = {itemId:id};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getItem'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.addRoom = async function(prefix,description)
{
    var bp = require('../Path.js');
  
    var obj = {prefix:prefix,description:description};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/addRoom'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.addShip = async function(prefix)
{
    var bp = require('../Path.js');
  
    var obj = {prefix:prefix};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/addShip'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.addTerm = async function(prefix)
{
    var bp = require('../Path.js');
  
    var obj = {prefix:prefix};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/addTerm'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.addUnit = async function(prefix,order,userName)
{
    var bp = require('../Path.js');
  
    var obj = {prefix:prefix,order:order,userName:userName};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/addUnit'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.getItemDetail = async function(id)
{
    var bp = require('../Path.js');
  
    var obj = {id:id};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getItemDetail'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.getItemMessages = async function(id)
{
    var bp = require('../Path.js');
  
    var obj = {id:id};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getItemMessages'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.getBudgetSummary = async function(id)
{
    var bp = require('../Path.js');
  
    var obj = {id:id};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getBudgetSummary'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.getSalesCodes = async function(clientId)
{
    var bp = require('../Path.js');
  
    var obj = {clientId:clientId};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getSalesCodes'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.getSalesCodeDetail = async function(clientId,salesCode,ibt)
{
    var bp = require('../Path.js');

    ibt = ibt.toLowerCase();
    ibt = ibt.substring(0,1).toUpperCase() + ibt.substring( 1 );
  
    var obj = {clientId:clientId,salesCode:salesCode,ibt:ibt};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getSalesCodeDetail'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

// const { clientId, userName, designer, assistant, dt } = req.body;


exports.createNewItem = async function(clientId,userName,designer,assistant,dt)
{
    var bp = require('../Path.js');
  
    var obj = {clientId:clientId,userName:userName,designer:designer,assistant:assistant,dt:dt};
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/createNewItem'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

//getBudgetReasons

exports.getBudgetReasons = async function()
{
    var bp = require('../Path.js');
  
    var obj = { };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getBudgetReasons'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
        return null;
    }    

}

exports.addBudgetItem = async function(clientId,projectId,BQ,BUC,BC,Desc,Code,Spec,Dept,User,Udate)
{
    var bp = require('../Path.js');
  
    var obj = { clientId:clientId,projectId:projectId,BQ:BQ,BUC:BUC,BC:BC,Desc:Desc,Code:Code,Spec:Spec,Dept:Dept,User:User,Udate:Udate };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/addBudgetItem'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
    }
    catch(e)
    {
        alert(e.toString());
        return false;
    }    
    return true;

}

exports.getMessages = async function(id)
{
    var bp = require('../Path.js');
  
    var obj = { id:id };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getMessages'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
    }
    catch(e)
    {
        alert(e.toString());
    }    

}

exports.getRooms = async function(clientId)
{
    var bp = require('../Path.js');
  
    var obj = { clientId:clientId };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getRooms'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
    }    

}

exports.getClientApprovalList = async function(clientId,filter)
{
    var bp = require('../Path.js');
  
    var obj = { clientId:clientId, filter:filter };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getClientApprovalList'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
    }    

}

//  const { role, room, item, designer, assistant, vendor, shipTo, PQ, SQ, BQ, unit, desc,  proposal, SC, dept, PONo, status, EXPStatus, RCVStatus, PUC, PST, SUC, SST, BUC, BST, Frt, Cra, install, other, ST, PDP, PD, SM, shipVia, terms, FOB, priority, CBy, CDate, PURL, installDate, id  } = req.body;

exports.saveDetails = async function(role, room, item, designer, assistant, vendor, shipTo, PQ, SQ, BQ, unit, desc,  proposal, SC, dept, PONo, status, EXPStatus, RCVStatus, PUC, PST, SUC, SST, BUC, BST, Frt, Cra, install, other, ST, PDP, PD, SM, shipVia, terms, FOB, priority, CBy, CDate, PURL, installDate, id)
{
    var bp = require('../Path.js');
  
    var obj = { role:role, room:room, item:item, designer:designer, assistant:assistant, vendor:vendor, shipTo:shipTo, PQ:PQ, SQ:SQ, BQ:BQ, unit:unit, desc,  proposal, SC, dept, PONo, status, EXPStatus, RCVStatus, PUC, PST, SUC, SST, BUC, BST, Frt, Cra, install, other, ST:ST, PDP:PDP, PD:PD, SM:SM, shipVia:shipVia, terms:terms, FOB:FOB, priority:priority, CBy:CBy, CDate:CDate, PURL:PURL, installDate:installDate, id:id };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/saveDetails'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
    }    

}

exports.getClientProject = async function()
{
    var bp = require('../Path.js');
  
    var obj = { };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getClientProject'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
        alert(e.toString());
    }    

}

exports.copy = async function(clientId,itemId)
{
    var bp = require('../Path.js');
  
    var obj = { clientId:clientId, itemId:itemId };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/copy'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
//        alert(e.toString());
    }    

}

exports.transfer = async function(clientId,itemId)
{
    var bp = require('../Path.js');
  
    var obj = { clientId:clientId, itemId:itemId };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/transfer'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        return res;
    }
    catch(e)
    {
//        alert(e.toString());
    }    

}

exports.todaysDate = function()
{
    let d = new Date();
    let dt = (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
    return dt;
}

let USDollar = new Intl.NumberFormat('en-US', 
{
    style: 'currency',
    currency: 'USD',
});

exports.createCurrencyString = function( obj, killLeading )
{
    let val = 0;

    try
    {
        let val = parseFloat( obj );
    }
    catch(e){}

    let txt = USDollar.format( val ).substring( killLeading );

    return txt;
}

exports.conforms = function( Address_ID )
{
    if( Address_ID.length != 7 )
    {
        return false;
    }

    for( let i=0; i<Address_ID.length; i++ )
    {
        let thisChar = Address_ID[i];
        if( !(thisChar >= '0' && thisChar <= '9') )
        {
            return false;
        }
    }

    return true;
}

exports.searchLibraries = async function( codes, type, activeOnly, id )
{
    var bp = require('../Path.js');
  
    var obj = { codes:codes, type:type, activeOnly:activeOnly, id:id };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/searchLibraries'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        if( res.error.trim().length > 0 )
        {
        }
        else
        {
            return res;
        }
    }
    catch(e)
    {
        alert(e.toString());
        return;
    }    

}

exports.getAddressData = async function( id )
{
    var bp = require('../Path.js');
  
    var obj = { id:id };
    var js = JSON.stringify(obj);

    try
    {    
        const response = await fetch(bp.buildPath('api/getAddressData2'),
            {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});

        var res = JSON.parse(await response.text());

        if( res.error.trim().length > 0 )
        {
        }
        else
        {
            return res;
        }
    }
    catch(e)
    {
        alert(e.toString());
        return;
    }    

}

exports.setTextField = function( setFunction, key, json )
{
    let txt = json[key];
    if( txt == null )
    {
        txt = "";
    }

    try
    {
        txt = txt.trim();
        setFunction( txt );
    }
    catch(e)
    {
        setFunction("");
    }
}

exports.setTextFieldOrZero = function( setFunction, key, json )
{
    let txt = json[key];
    if( txt == null )
    {
        txt = "0";
    }

    try
    {
        txt = txt.trim();
        if( txt.length == 0 )
        {
            txt = "0";
        }
        setFunction( txt );
    }
    catch(e)
    {
        setFunction("0");
    }
}
