import React, { useState, useEffect, useRef } from 'react';

const ReportButton = ({ title, color, textColor, onClick }) => {
  const [isClicked, setIsClicked] = useState(false); // State to manage the clicked state
  const buttonRef = useRef(null); // Ref to keep track of the button element

  useEffect(() => {
    // Function to handle clicks outside the button
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsClicked(false); // Reset clicked state if clicked outside
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);

  const buttonStyle = {
    backgroundColor: color, // Button background color
    color: textColor, // Text color
    padding: '10px', // Adjust padding for better button appearance
    margin: '1px', // Remove margin
    border: 'none', // Initial border style
    borderRadius: '4px', // Border radius for rounded corners
    cursor: 'pointer', // Cursor style on hover
    transition: 'background-color 0.3s, box-shadow 0.3s, border 0.3s', // Transition for smooth effects
    position: 'relative', // To position the border outside the button
    width: '150px', // Set fixed width
    height: '32px', // Set fixed height
    display: 'flex', // Use flexbox for centering text
    alignItems: 'center', // Center text vertically
    justifyContent: 'center' // Center text horizontally
  };

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = darken(color, 0.2); // Darken background color on hover
    e.target.style.boxShadow = `0 0 5px 2px ${color}50`; // Add shadow effect on hover
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = color; // Reset background color on mouse leave
    e.target.style.boxShadow = isClicked ? `0 0 5px 2px ${color}50` : 'none'; // Keep shadow if clicked
  };

  const handleClick = (e) => {
    setIsClicked(true); // Set clicked state to true
    if (onClick) {
      onClick(e); // Call the onClick handler if provided
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', margin: '0px' }} ref={buttonRef}>
      <button
        style={buttonStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {title}
      </button>
      {isClicked && (
        <div
          style={{
            position: 'absolute',
            top: '0px', // Remove space around the border
            left: '0px', // Remove space around the border
            right: '0px', // Remove space around the border
            bottom: '0px', // Remove space around the border
            border: `4px solid ${lighten(color, 0.3)}`, // Adjust border thickness and lighten color
            borderRadius: '4px', // Border radius to match button
            pointerEvents: 'none', // Allow clicking through the border
            transition: 'border-color 0.3s', // Smooth transition for border color
          }}
        />
      )}
    </div>
  );
};

// Helper function to darken color
const darken = (color, percent) => {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00FF,
    B = f & 0x0000FF;
  return "#" + (
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  ).toString(16).slice(1);
};

// Helper function to lighten color
const lighten = (color, percent) => {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00FF,
    B = f & 0x0000FF;
  return "#" + (
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  ).toString(16).slice(1);
};

export default ReportButton;
