exports.checkSession = 
function checkSession()
{
    const timeOutSeconds = 60 * 60 * 20;

    let day = parseInt(localStorage.getItem('day' ));
    let hours = parseInt(localStorage.getItem('hours' ));
    let minutes = parseInt(localStorage.getItem('minutes' ));
    let seconds = parseInt(localStorage.getItem('seconds' ));

    let cur = new Date();
    let cday = cur.getDay();
    let chours = cur.getHours();
    let cminutes = cur.getMinutes();
    let cseconds = cur.getSeconds();

    const previousSeconds = seconds + minutes * 60 + hours * 60 * 60;
    const currentSeconds = cseconds + cminutes * 60 + chours * 60 * 60;

    if( day < cday - 1 )
    {
        return true;
    }
    if( day === cday - 1 )
    {
        let diff = (24 * 60 * 60 - previousSeconds) + currentSeconds;
        if( diff < timeOutSeconds )
        {
            return false;
        }
        return true;
    }
    else
    {
        let diff = currentSeconds - previousSeconds;
        if( diff < timeOutSeconds )
        {
            return false;
        }
        return true;
    }
}

exports.resetSession = 
function resetSession()
{
    const c = new Date();
    localStorage.setItem('day', c.getDay() );
    localStorage.setItem('hours', c.getHours() );
    localStorage.setItem('minutes', c.getMinutes() );
    localStorage.setItem('seconds', c.getSeconds() );
}
