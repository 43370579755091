import React, { useRef, useEffect, useState } from 'react';
import Menu from '../components/Menu';
import ClientInfo from '../components/ClientInfo';
import ProjectInfo from '../components/ProjectInfo';
import Footer from '../components/Footer';
import InfoButtons from '../components/InfoButtons';
import AddBudgetItem from '../components/AddBudgetItem';
import SetPriority from '../components/SetPriority';
import BudgetSummary from '../components/BudgetSummary';
import InstallBook from '../components/InstallBook';
import ClientApproval from '../components/ClientApproval';
import TransferCopy from '../components/TransferCopy';
import Proposal from '../components/Proposal';
import AddressDetails from '../components/AddressDetails';

import Checkbox from "@mui/material/Checkbox";

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {Link} from '@material-ui/core';

import Helpers from './project2Helpers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  
const ProjectsPage = () =>
{
    function doRefresh()
    {
        window.location.href = "/Project2?clientid=" + clientId;
    }

    const childRef = useRef(null);
    const childRef2 = useRef(null);
    const childRef3 = useRef(null);
    const childRef4 = useRef(null);
    const childRef5 = useRef(null);
    const childRef6 = useRef(null);
    const childRef7 = useRef(null);
    const childRef8 = useRef(null);

    let userData = localStorage.getItem('user_data');
    let ud = JSON.parse(userData);

//#region topButtons    
    const onInfoCreateClick = async event => 
    {
        event.preventDefault();
        childRef8.current();
    }
    const onInfoSearchClick = async event => 
    {
        event.preventDefault();
        alert('onInfoSearchClick()');
    }
    const onInfoChangeActiveOnly = async event => 
    {
        event.preventDefault();
        alert('onInfoChangeActiveOnly()');
    }
    let infoSort = 'Room';
    const onInfoChangeSort = async event => 
    {
        event.preventDefault();
        infoSort = event.target.value;
        getItems();
    }
//#endregion

//#region tenButtons
    const _addNewItem = async event => 
    {
        event.preventDefault();
        let link = `/ProjectDetails?id=0&new=yes&projectid=${clientId}&clientId=${clientId}&ds=LJ&as=${ud.employeeName}&action=NewItem`;
        window.location.href = link;
    }

    const _addBudgetItem = async event => 
    {
        event.preventDefault();
        childRef.current();
    }
    function addBudgetItemResult( amount, budgetForDesc, budgetForCode )
    {
        let d = new Date();
        let dt = (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
        let enteredBy = ud.login;
        if( enteredBy.length > 9 )
        {
            enteredBy = enteredBy.sub(0,9);
        }
        return Helpers.addBudgetItem(clientId,"4444444",1,amount,amount,budgetForDesc,budgetForCode, "Yes", "01",enteredBy,dt);
    }

    const _createProposal = async event => 
    {
        event.preventDefault();
        if( _checkedProposalList.length > 0 )
        {
            childRef7.current(clientId);
        }
        else
        {
            alert( "You don't have anythinhg selected for a proposal.");
        }
    }
    const _createPO = async event => 
    {
        event.preventDefault();
        alert('_createPO()');
    }
    const _copy = async event => 
    {
        event.preventDefault();
        if( _checkedItemList.length > 0 )
        {
            childRef6.current("copy");
        }
        else
        {
            alert( "You don't have any selected items.");
        }
    }
    function getListOfSelectedIds()
    {
        return _checkedItemList;
    }
    function getListOfSelectedProposalIds()
    {
        return _checkedProposalList;
    }
    const _transfer = async event => 
    {
        event.preventDefault();
        if( _checkedItemList.length > 0 )
        {
            childRef6.current("transfer");
        }
        else
        {
            alert( "You don't have any selected items.");
        }
    }
    const _budgetSummary = async event => 
    {
        event.preventDefault();
        childRef3.current();
    }
    const _installBook = async event => 
    {
//        event.preventDefault();
        childRef4.current();
    }
    const _changeOrders = async event => 
    {
        event.preventDefault();
        alert('_changeOrders()');
    }
    const _clientApproval = async event => 
    {
        event.preventDefault();
        childRef5.current();
//        alert('_clientApproval()');
    }
//#endregion

    const queryParameters = new URLSearchParams(window.location.search);
    const clientId = queryParameters.get("clientid");
    localStorage.setItem( "ClientID", clientId );
    const [_name,setName] = useState('');

    const [_designer, setDesigner] = useState('');
    const [_assistant, setAssistant] = useState('');
    const [_installDate, setInstallDate] = useState('');
    const [_clientProject, setClientProject] = useState('');

    let _divObj;

    let rowCounter = 1;

//#region statcolor
//#endregion

    let data2 =
    [
        { "id":1, "ImageName":"images/noimage.png","DescAndBudget":"", "Room":"", "Vendor":"", "Proposal":"", "PO":"", "Sales Code":"", "Expediting":"","Cost":"", "Acknowledgement Date":"", 
        "ACKNO":"", "Est Ship Date":"", "Date Shipped":"","Received":"","Delivered":"" }
    ];
    const [data, setData] = useState(data2);

function _respondToPriority( value )
{
    if( value == 'red' || value == 'blue' || value == 'green' )
    {
        let divId = localStorage.getItem('divId' );
        let ID2 = localStorage.getItem('ID2' );

        _divObj.style.backgroundColor = value;
        Helpers.callPriorityAPI( ID2, value );
    }
}

function setPriority(event,ID2,stateColor, ackDate, divId)
{
    _divObj = event.target;
    localStorage.setItem('divId', divId );
    localStorage.setItem('ID2', ID2 );
    childRef2.current(ID2,stateColor,ackDate,event.clientX,event.clientY,divId);
}

//#region columndata
    let columns = 
    [
      { field: "ImageName", headerName: "", width: 120, height:120, headerClassName: 'custom-header-projectlist', renderCell: ( params ) => {

        let url = Helpers.getImageURL( params.value, clientId );

        return(
        <div>
            <img src={url} alt='' width={100} height={100} />
        </div>
        );
        }},
      { field: "DescAndBudget", headerName: "Item", padding: 0, width: 340, headerClassName: 'custom-header-projectlist', renderCell: (params) => {
        const tokens = Helpers.sm( params.value );

        // [0] Description
        // [1] Budget Cost
        // [2] EXPStatus
        // [3] Priority 01
        // [4] Priority 02
        // [5] Quantity
        // [6] Cost
        // [7] ID2
        // [8] ItemSize
        // [9] Unit
        // [10] Acknowledge Date

        let priority = "white";
        let statusColor = "white";
        let foreColor = "white";
        let additionalInfo1 = '';
        let additionalInfo2 = '';

        let priorityDivId = "PI" + rowCounter;
        let checkBoxId = "CB" + rowCounter;
        rowCounter++;

        if( tokens != null && tokens.length > 2 )
        {
            priority = Helpers.getColor( tokens );
            statusColor = Helpers.getStatusColor( tokens[2] );
            foreColor = Helpers.getForeColor( tokens[2] );

            if( tokens[0].indexOf( "Budget for") < 0 )
            {
                additionalInfo1 = tokens[8];
                additionalInfo2 += ("Qty: " + tokens[5] + " Unit: " + tokens[9]);
            }
            else
            {
                additionalInfo1 = "Budget: " + tokens[1];
            }

            let target = `/ProjectDetails?id=${clientId}&clientId=${clientId}&action=edit`;
        }

        return(
        <div>
            <div id={priorityDivId} onClick={(event)=>{setPriority(event,`${tokens[7]}`,`${priority}`,`${tokens[10]}`,priorityDivId);}} style={{float:'left',height:'100px',width:'19px',backgroundColor:`${priority}`}}></div>
            <div style={{whiteSpace:'normal',wordWrap:'break-word',float:'left',width:270,height:'100px'}}>
                <a style={{whiteSpace:'normal',wordWrap:'break-word'}} href={`/ProjectDetails?id=${tokens[7]}&clientId=${clientId}&action=edit`}>{tokens[0]}</a> <span style={{color:`${foreColor}`,backgroundColor:`${statusColor}`}}>{tokens[2]}</span><br />
                {additionalInfo1}<br />{additionalInfo2}
            </div>
            <div style={{float:'left',width:'20px'}}><Checkbox onClick={updateCheckedItems} value={tokens[7]} id={checkBoxId} style={{padding:0}} /></div>
        </div>
        );
        }},
      { field: "Room", headerName: "Room", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Vendor", headerName: "Vendor", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Proposal", headerName: "Proposal", width: 100, headerClassName: 'custom-header-projectlist', renderCell: (params) => {
        let tokens = params.value.split('~');

        return(
            <div><Checkbox value={tokens[1]} onClick={updateCheckedProposals} style={{padding:0}} /> <Link href={`/Proposal?id=${clientId}&p=${tokens[0]}`}>{tokens[0]}</Link></div>
        );
      }},
      { field: "PO", headerName: "PO", width: 100, headerClassName: 'custom-header-projectlist', renderCell: (params) => (
        <div><Checkbox style={{padding:0}} /> <Link href={`/PO?id=${clientId}&p=${params.value}`}>{params.value}</Link></div>
        )},
      { field: "Sales Code", headerName: "Sales Code", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Expediting", headerName: "Expediting", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Cost", headerName: "Purchase", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Acknowledgement Date", headerName: "Ack Date", width: 90, headerClassName: 'custom-header-projectlist' },
      { field: "ACKNO", headerName: "Ack No.", width: 100, headerClassName: 'custom-header-projectlist' },
      { field: "Est Ship Date", headerName: "Est Ship", width: 90, headerClassName: 'custom-header-projectlist' },
      { field: "Date Shipped", headerName: "Shipped", width: 90, headerClassName: 'custom-header-projectlist' },
      { field: "Received", headerName: "Received", width: 90, headerClassName: 'custom-header-projectlist' },
      { field: "Delivered", headerName: "Delivered", width: 90, headerClassName: 'custom-header-projectlist' }
    ];
//#endregion

    async function getItems()
    {
        try
        {
            document.body.style.cursor = "wait";

            let bp = require('../Path.js');
            let obj = {clientId:clientId,sort:infoSort};
            let js = JSON.stringify(obj);
            const response = await fetch(bp.buildPath('api/getItems'),
                {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
            let res = JSON.parse(await response.text()).retData;
            res = JSON.parse( res );

            let textVersion = JSON.stringify( res );

//            let index = textVersion.indexOf( '[');
//            textVersion = textVersion.substring(index,textVersion.length-1);
            let res2 = JSON.parse( textVersion );

            // Do something here.
            setData( res2 );

            document.body.style.cursor = "pointer";
        }
        catch(e)
        {
            document.body.style.cursor = "pointer";
            console.log( e );
        }
    };

    async function getAddressData()
    {
        try
        {
            let bp = require('../Path.js');
            let obj = {clientId:clientId};
            let js = JSON.stringify(obj);
            const response = await fetch(bp.buildPath('api/getAddressData'),
                {method:'POST',body:js,headers:{'Content-Type': 'application/json'}});
            let res = JSON.parse(await response.text()).retData;
            res = JSON.parse( res );

            let textVersion = JSON.stringify( res );

            let index = textVersion.indexOf( '[');
            textVersion = textVersion.substring(index+1,textVersion.length-1);
            let res2 = JSON.parse( textVersion );

            setDesigner( res2["Designer"] );
            setAssistant( res2["Assistant"] );
            let id = res2["Install Date"].toString();
            index = id.indexOf( "T" );
            if( index > 0 )
            {
                id = id.substring( 0, index );
            }
            setInstallDate(id);

        }
        catch(e)
        {
            console.log( e );
        }
    };

	useEffect(() => 
	{
		document.title = 'Projects';
        let name = localStorage.getItem(clientId);
        setName( name );
        setClientProject( clientId );
        getAddressData();
        getItems();
	}, []);

    var st = require('../checkSessionTimeout.js');
    if( st.checkSession() )
    {
        window.location.href = '/';
    }

    const [_checkedItems,setCheckedItems] = useState([]);
    let _checkedItemList = [];
    function updateCheckedItems( event )
    {
        let id = event.target.value;
        let isChecked = event.target.checked;
        let newArray = [];
        let found = false;
        for( let i=0; i<_checkedItemList.length; i++ )
        {
            if( _checkedItemList[i] != id )
            {
                newArray.push( _checkedItemList[i] );
            }
            if( isChecked && _checkedItemList[i] == id )
            {
                found = true;
                newArray.push( _checkedItemList[i] );
            }
        }
        if( !found && isChecked )
        {
            newArray.push( id );
        }
        _checkedItemList = newArray;
    }

    const [_checkedProposal,setCheckedProposal] = useState([]);
    let _checkedProposalList = [];
    function updateCheckedProposals( event )
    {
        let id = event.target.value;
        let isChecked = event.target.checked;
        let newArray = [];
        let found = false;
        for( let i=0; i<_checkedProposalList.length; i++ )
        {
            if( _checkedProposalList[i] != id )
            {
                newArray.push( _checkedProposalList[i] );
            }
            if( isChecked && _checkedProposalList[i] == id )
            {
                found = true;
                newArray.push( _checkedProposalList[i] );
            }
        }
        if( !found && isChecked )
        {
            newArray.push( id );
        }
        _checkedProposalList = newArray;
    }

    return(
<div>

    <Menu />

    <ProjectInfo changeSort={onInfoChangeSort} changeActiveOnly={onInfoChangeActiveOnly} searchClick={onInfoSearchClick} createClick={onInfoCreateClick} designer={_designer} assistant={_assistant} installDate={_installDate} clientProject={_clientProject} />
    <ClientInfo email={'joe@here.com'} totalCost={'12,456.78'} percent={'99'} projectName={_name} builder={'Home Builder'} address={'4110 Forest Island Dr., Orlando, FL 32826'} phone={'123-456-7890'} fax={'098-765-4321'} clientProject={_clientProject} />
    <InfoButtons addNewItem={_addNewItem} addBudgetItem={_addBudgetItem} createProposal={_createProposal} createPO={_createPO} copy={_copy} transfer={_transfer} budgetSummary={_budgetSummary} installBook={_installBook} changeOrders={_changeOrders} clientApproval={_clientApproval} />
    <DataGrid
        rows={data}
        columns={columns}
        initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}

        allowTextWrap={true}
        rowHeight={100}

        sx={{
            [`& .MuiDataGrid-cell`]: {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal',
                border: `1px solid transparent`,
                borderRight: `1px solid #dddddd`,
                borderBottom: `1px solid #dddddd`,
                wordWrap: 'break-word',
                wordBreak: 'break-word'

            },
            [`& .MuiDataGrid-columnHeader`]: {
              maxHeight: 'none !important',
              height: 'auto !important',
              whiteSpace: 'inherit !important',
              overflow: 'inherit !important',
              lineHeight: '24px !important'
            },
            [`& .MuiDataGrid-columnHeaderTitle`]: {
              whiteSpace: 'normal !important',
            },
        }}        
    />            

    <br />
    <br />
    <Footer />
    <AddBudgetItem ref={childRef} adder={addBudgetItemResult} refreshMe={doRefresh} />
    <SetPriority handler={_respondToPriority} ref={childRef2} />
    <BudgetSummary ref={childRef3} Description={_name} clientId={clientId} />
    <InstallBook ref={childRef4} clientId={clientId} Description={_name} />
    <ClientApproval ref={childRef5} clientId={clientId} />
    <TransferCopy ref={childRef6} getListOfSelectedIds={getListOfSelectedIds} refreshMe={doRefresh} />
    <Proposal ref={childRef7} getListOfSelectedIds={getListOfSelectedProposalIds} />
    <AddressDetails ref={childRef8} />
    
</div>
);

};

export default ProjectsPage;
