import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typeography, Button, button } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../pages/project2Helpers';
import ClientApprovalForm from './ClientApprovalForm';

const Proposal = forwardRef((props,ref) =>
{

    const childRef = useRef(null);

	useEffect(() => 
	{
        getData();
	}, []);

    const [openProposalItem, setOpenProposalItem] = useState(false);
    const [_clientProject, setClientProject] = useState([]);
    const [_clientValue,setClientValue] = useState('');
    const [_proposal,setProposal] = useState('M0001');
    const [_dateTime,setDateTime] = useState('');
    const [_descriptionData,setDescriptionData] = useState([]);
    const [_detailData,setDetailData] = useState([]);

    async function getData()
    {
        let _itenIdList = props.getListOfSelectedIds();
        let descArray = [];
        let detArray = [];
        for( let i=0; i<_itenIdList.length; i++ )
        {
            let itemData = await Helpers.callGetItem( _itenIdList[i] );
            itemData = JSON.parse( itemData.retData );

            let value = itemData[0]['Item Description'];
            descArray.push({"Description":value});

            detArray.push({"Qty":itemData[0]['Purchase Quantity'],"Unit":itemData[0]['Unit'],
                "UnitCost":Helpers.createCurrencyString(itemData[0]['Budget Unit Cost'],0),"Cost":Helpers.createCurrencyString(itemData[0]['Budget Cost'],0),
                "Room":itemData[0]['Room'],"Vendor":itemData[0]['Vendor']});
        }
        setDescriptionData( descArray );
        setDetailData( detArray );
    }

//#region AddBudgetItemCode

    const handleClickOpenProposalItem = (clientId) => 
    {
        setClientValue( clientId );
        setDateTime( Helpers.todaysDate() );
        getData();
        setOpenProposalItem(true);
    };

    const handleCloseProposalItem = () => 
    {
        setOpenProposalItem(false);
    };

    ref.current = handleClickOpenProposalItem;
//#endregion

    let needRefresh = false;

    function handleOK()//const handleOk = () =>
    {

        handleCloseProposalItem();
    };

    function updateClientProject( event )
    {
        setClientValue( event.target.value );
    }

    const [_okButtonText,setOKButtonText] = useState('OK');
    const [_disabled, setDisabled] = useState(false);

    function onClientApproval(event)
    {
        event.preventDefault();
        childRef.current('approval');
    }
    function onJobCostWorksheet(event)
    {
        event.preventDefault();
        childRef.current('job cost');
    }

    function onSave()
    {
    }

    return(
        <div>
<Dialog 
    open={openProposalItem}
    onClose={handleCloseProposalItem}

    fullWidth
    maxWidth="md"

    >
    <DialogTitle>

        <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:'50%'}}>
                        <table style={{width:'100%'}}>
                            <td style={{width:'20%'}}>
                                <span style={{fontSize:23}}>Proposal</span>
                            </td>
                            <td style={{width:'80%'}}>
                                <button onClick={onClientApproval} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#198759',color:'white'}} type="button">Client Approval</button>
                                <button onClick={onJobCostWorksheet} style={{padding:'2px', border:'none', margin:'4px', backgroundColor:'#198759',color:'white'}} type="button">Jobs Cost Worksheet</button>
                            </td>
                        </table>
                    </td>
                    <td style={{width:'50%'}} align="right">
                        <IconButton style={{backgroundColor:'#ffaaaa'}} onClick={handleCloseProposalItem}>
                            <CloseIcon />
                        </IconButton>
                    </td>
                </tr>
            </table>

    </DialogTitle>
    <DialogContent>
        <table>
            <tr>
                <td>Client:</td>
                <td><b>{_clientValue}</b></td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>Project:</td>
                <td>Proposal:</td>
                <td>{_proposal}</td>
            </tr>
            <tr>
                <td>Date:</td>
                <td><b>{_dateTime}</b></td>
                <td>&nbsp;</td>
            </tr>
        </table><br />
        Special Instructions:<br />
        <textarea id="ta1" name="ta1" rows="4" cols="90"></textarea><br />
        Notes:<br />
        <textarea id="ta2" name="ta2" rows="4" cols="90"></textarea><br />
        Proposal Description:<br />
        <textarea id="ta3" name="ta3" rows="4" cols="90"></textarea><br /><br />

        <table border="1">
            <tr>
                <td valign="top">
                <table>
                    <thead>
                    <tr>
                        <th style={{padding:'5px'}}>Item Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        _descriptionData.map((obj) => {
                            return(
                                <tr>
                                    <td>
                                        {obj.Description}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
                </td>

                <td valign="top">
                <table>
                    <thead>
                    <tr>
                        <th style={{padding:'5px'}}>Qty</th>
                        <th style={{padding:'5px'}}>Unit</th>
                        <th style={{padding:'5px'}}>Unit Cost</th>
                        <th style={{padding:'5px'}}>Cost</th>
                        <th style={{padding:'5px'}}>Room</th>
                        <th style={{padding:'5px'}}>Vendor</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        _detailData.map((obj) => {
                            return(
                                <tr>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.Qty}
                                    </td>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.Unit}
                                    </td>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.UnitCost}
                                    </td>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.Cost}
                                    </td>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.Room}
                                    </td>
                                    <td align="right" style={{padding:'5px'}}>
                                        {obj.Vendor}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
                </td>
            </tr>
        </table>
        

    </DialogContent>
    <DialogActions>
        <button onClick={onSave} style={{width:'100px', height:'40px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#6666ff',color:'white'}} type="button">Save and Exit</button>
        <Button onClick={handleCloseProposalItem}>
            Cancel
        </Button>
    </DialogActions>
</Dialog>

<ClientApprovalForm clientId={_clientValue} ref={childRef} />

</div>
    );

});

export default Proposal;
