import React, { useState } from 'react';

function LibrarySpecs(props)
{

    const [_ID, setID] = useState('');
    function updateID( event )
    { 
        setID( event.target.value ); 
    }

    const [_codes, setCodes] = useState('');
    function updateCodes( event )
    { 
        setCodes( event.target.value ); 
    }

    const [_type, setType] = useState('Vendor');
    function updateType( event )
    { 
        setType( event.target.value ); 
    }

    const [_checked, onActiveChange] = useState(true);

    async function doSearch()
    {
        props.doSearch(_ID,_type,_checked,_codes);
    }

    async function doClear()
    {
        setID('');
        setType('Vendor');
        setCodes('');
        onActiveChange(true);
    }

    async function doCreateNew()
    {
        props.doCreateNew();
    }

    async function doExport()
    {
        props.doExport();
    }

    return(
        <div>
            <br />
            <div>
                <table>
                <tr><td>
                    ID: <input id="id" value={_ID} onChange={updateID} style={{width:150}} type="text" />
                    &nbsp;&nbsp;Type:   <select id="type" onChange={updateType} >
                                <option value="Vendor" selected>Vendor</option>
                                <option value="Client">Client</option>
                                <option value="Project">Project</option>
                                <option value="Office">Office</option>
                                <option value="Retail">Retail</option>
                                <option value="Showroom">Showroom</option>
                                <option value="Mfg">Mfg</option>
                                <option value="Ship To">Ship To</option>
                                <option value="Prospect">Prospect</option>
                                <option value="Personal">Personal</option>
                                <option value="Other">Other</option>
                                <option value="All">All</option>
                            </select>
                    &nbsp;&nbsp;Codes: <input id="id" value={_codes} onChange={updateCodes} style={{width:250}} type="text" />
                </td></tr>
                <tr><td>
                    <button onClick={doSearch} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#00aa00',color:'white'}} type="button">Search</button>
                    <button onClick={doClear} style={{width:'100px', height:'28px', padding:'2px', border: '2px solid orange', margin:'4px', backgroundColor:'#eeeeee',color:'orange'}} type="button">Clear</button>
                </td></tr>
                </table>
                <hr />
                <input
                    type="checkbox"
                    checked={_checked}
                    onChange={onActiveChange}
                /> Active Only
                <hr />
                <table width="100%"><tr>
                    <td width="50%"><button onClick={doCreateNew} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#2222ff',color:'white'}} type="button">Create New</button></td>
                    <td align="right" width="50%"><button onClick={doExport} style={{width:'100px', height:'28px', padding:'2px', border:'none', margin:'4px', backgroundColor:'#00aa00',color:'white'}} type="button">Export</button></td>
                </tr></table>
            </div>
        </div>
        );
       
}

export default LibrarySpecs;
